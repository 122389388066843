import { useEffect, useRef, useState } from "react";
import {getDocumentUrl} from "@helper/media";
import useOnScreen from "@hooks/useOnScreen";

import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import Row from "@components/Modules/Utils/Row/Row";

import styles from './Download.module.scss'

interface FieldData {
  fieldData: any;
  field_download_title: string;
  field_download_item: any;
}

export default function Download({ fieldData: {field_download_title, field_download_item} }: FieldData) {
  const ref: any = useRef(null);
  const onScreen: boolean = useOnScreen(ref);
  const [loaded, setLoadead] = useState(false);
  useEffect(()=> {
    if(onScreen) setLoadead(true);
  }, [onScreen])
  return (
    <Fade ref={ref} in={loaded} appear={false} timeout={1000} style={{transitionDelay: '100ms'}}>
      <Container maxWidth={false} className={styles.download}>
        <Row justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Row rowSpacing={16}>
              {field_download_title && (
                <Grid item xs={12}>
                  <Typography variant="h6">{field_download_title}</Typography>
                </Grid>
              )}

              {field_download_item && field_download_item.map((el, index) => {
                const document = el.field_download_item_document && getDocumentUrl(el.field_download_item_document);

                return (
                  <Grid item xs={12} lg={6} key={index}>
                    <a className={styles.downloadItem} href={document} target="_blank" rel="noreferrer">
                      <PictureAsPdfIcon />
                      {el?.field_download_item_label && <Typography variant="bodyL" fontWeight={300}>{el.field_download_item_label}</Typography>}
                      <DownloadIcon color="primary" fontSize="large" sx={{marginLeft: 'auto'}} />
                    </a>
                  </Grid>
                )
              })}
            </Row>
          </Grid>
        </Row>
      </Container>
    </Fade>
  )
}
