import React, {useState, useRef, useEffect} from 'react';
import Image from 'next/image';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import Row from '@components/Modules/Utils/Row/Row';

import AddCircle from '@mui/icons-material/AddCircle';
import RemoveCircle from '@mui/icons-material/RemoveCircle';

import { getThumbnailAlt, getThumbnailUrl } from '@helper/media';
import useOnScreen from "@hooks/useOnScreen";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Navigation} from "swiper";

import styles from './Management.module.scss'

export default function Management({fieldData: { field_management_item }}) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const ref: any = useRef(null);
  const onScreen: boolean = useOnScreen(ref);
  const [loaded, setLoadead] = useState(false);

  useEffect(()=> {
    if(onScreen) setLoadead(true);
  }, [onScreen])

  const params: object = {
    modules: [Pagination, Navigation],
		slidesPerView:1.2,
    spaceBetween:0,
    pagination: true,
    navigation: false,
    setWrapperSize: true,
	}

  return(
    (field_management_item?.length > 1 && isMobile) ? (
      <div className={styles.management}>
        <Swiper {...params} className={styles.swiper}>
            {field_management_item && field_management_item.map((el, i) => {
              return (
                <SwiperSlide key={i}>
                  <ManagementItem el={el}/>
                </SwiperSlide>
              )
            })}
          </Swiper>
      </div>
    ) : (
      <Fade ref={ref} in={loaded} appear={false} timeout={1000} style={{transitionDelay: '100ms'}}>
        <Container maxWidth={false} className={styles.management}>
          <Row rowSpacing={16} py={16} justifyContent={field_management_item?.length == 1 ? "center" : "flex-start"}>

            {field_management_item && field_management_item.map((el, i) => {
              
              return (
                <React.Fragment key={i}>
                  {i% 3 === 0 && <Grid item lg={0} md={0} sx={{ display: { xs: 'none', lg: 'block' }, paddingLeft: `0 !important` }} />}
                  <Grid item xs={12} lg={4} className={styles.managementItemWrapper}>
                    <ManagementItem el={el}/>
                  </Grid>
                </React.Fragment>
              )
            })}

          </Row>
        </Container>
      </Fade>
    )
  )
}

function ManagementItem({el}) {
  const [active, setActive] = useState(false)

  const image = {
    url: getThumbnailUrl(el.field_management_item_image),
    alt: getThumbnailAlt(el.field_management_item_image),
    width: el.field_management_item_image?.field_media_image?.resourceIdObjMeta?.width,
    height: el.field_management_item_image?.field_media_image?.resourceIdObjMeta?.height
  }

  return (
    <Box className={`${styles.managementItem} ${active ? styles.active : ''}`}>
      {image.url ? <Image src={image.url} alt={image.alt} width={image.width} height={image.height} className={styles.image} quality={100}/> : null}

      <div className={styles.itemHeading}>
        {el.field_management_item_name && <Typography variant={active ? 'h6' : 'h5'} component="h5" className={styles.title}>{el.field_management_item_name}</Typography>}
        {el.field_management_item_subtitle && <Typography variant={active ? 'description' : 'overline'} textTransform="uppercase" className={styles.subtitle}>{el.field_management_item_subtitle}</Typography>}
      </div>

      {el.field_management_item_content && (
        <>
          {active && (
            <div className={styles.content}>
              <Typography variant='bodyS' fontWeight="light" component="div" dangerouslySetInnerHTML={{ __html: el.field_management_item_content.processed }} />
            </div>
          )}
    
          <div className={styles.icon}>
            {active ? <RemoveCircle color="primary" onClick={()=>setActive(!active)} sx={{fontSize: 32}}/> : <AddCircle color="primary" onClick={()=>setActive(!active)} sx={{fontSize: 32}}/>}
          </div>
        </>
      )}
      

    </Box>
  )
}