import { useEffect, useRef, useState } from "react";
import Image from "next/legacy/image";

import {getThumbnailAlt, getThumbnailUrl} from "@helper/media";
import useOnScreen from "@hooks/useOnScreen";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import IconButton from '@mui/material/IconButton';
import EastIcon from '@mui/icons-material/East';

import Row from '@components/Modules/Utils/Row/Row';

import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import * as Icons from '../../UI/CustomIcons/index';
import * as AnimatedSvg from '../../UI/AnimatedSvg/index';
import OndeTop from './assets/OndeTop';
import OndeBottom from './assets/OndeBottom';
import style from './Servizi.module.scss';
import Link from "next/link";

interface FieldData {
  fieldData: any;
  field_servizi_image?: string;
  field_servizi_background?: string;
  field_servizi_item?: any
}
interface FieldService {
  index: number,
  item: any,
  imageUrl: any,
  length: number
}

function TrackComponent(props: any) {
  const {items} = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  if(isMobile) return <AnimatedSvg.TrackMobile />

  return(
    <>
      {[7].includes(items) && <AnimatedSvg.TrackFull />}
      {[6,5,4].includes(items) && <AnimatedSvg.TrackSix />}
    </>
  )
}

export default function Servizi({ fieldData: { field_servizi_image, field_servizi_background, field_servizi_item }}: FieldData) {
  const ref: any = useRef(null);
  const onScreen: boolean = useOnScreen(ref);
  const [loaded, setLoadead] = useState(false);

  useEffect(()=> {
    if(onScreen) setLoadead(true);
  }, [onScreen])

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  
  const imageUrl = field_servizi_image && getThumbnailUrl(field_servizi_image);
  const imageAlt = field_servizi_image?.field_media_image?.resourceIdObjMeta?.alt;
  const imageWidth = field_servizi_image?.field_media_image?.resourceIdObjMeta?.width;
  const imageHeight = field_servizi_image?.field_media_image?.resourceIdObjMeta?.height;

  return (
    <Fade ref={ref} in={loaded} appear={false} timeout={1000} style={{transitionDelay: '100ms'}}>
      <div className={style.servicesWrapper} >

        {field_servizi_background ? (
            <Image 
              src={getThumbnailUrl(field_servizi_background)}
              alt={getThumbnailAlt(field_servizi_background)}
              layout='fill'
              objectFit={isMobile ? 'cover' : 'unset'}
              className={style.servicesBackground}
            />
        ) : (
          <>
            <div className={style.ondeTop}><OndeTop /></div>
            <div className={style.ondeBottom}><OndeBottom /></div>
          </>
        )}

        <Container maxWidth={false} className={style.serviceContainer}>
          <Row sx={{rowSpacing: {xs: 0, lg: 24}}} className={style.serviceRow}>

            {field_servizi_item && field_servizi_item.map((item, index) => <Service key={index} index={index} item={item} imageUrl={imageUrl} length={field_servizi_item.length} />)}

          </Row>
          {imageUrl &&
            <div className={style.imageElement}>
              <Image src={imageUrl} alt={imageAlt} width={imageWidth} height={imageHeight} quality={100}/>
            </div>
          }
        </Container>

      </div>
    </Fade>
  )
}


function Service({index, item, imageUrl, length}: FieldService) {

  let iconName = item.field_servizi_icon?.name?.replace(/\.[^/.]+$/, "");
  const Icon = iconName ? Icons[iconName] : false;

  let url = item.field_servizi_url?.url;
  let urlExternal = item.field_servizi_url && !url.startsWith('/') && !url.includes(process.env.NEXT_PUBLIC_BASE_URL);

  const ref: any = useRef(null);
  const onScreen: boolean = useOnScreen(ref);
  const [loaded, setLoadead] = useState(false);

  useEffect(()=> {
    if(onScreen) setLoadead(true);
  }, [onScreen])

  return(
    <>
      {imageUrl && index === 3 && <Grid item lg={3} md={false} sx={{ display: { xs: 'none', lg: 'block' } }} />}
      <Grid item xs={12} lg={3}>

        {index === 0 && <div className={style.track} ref={ref}>
          {loaded && <TrackComponent items={length}/>}
        </div>}

        <Box
          component={url ? Link : 'div'}
          href={url}
          target={urlExternal ? '_blank' : '_self'}
          rel={urlExternal ? 'noopener noreferrer' : ''}
          className={style.serviceItem}
        >
          {Icon && <div className={style.icon}><Icon /></div> }

          {item.field_servizi_title && <Typography variant='subtitle' className={style.title} >{item.field_servizi_title}</Typography>}
          {item.field_servizi_content && <Typography variant='bodyL' className={style.description} dangerouslySetInnerHTML={{ __html: item.field_servizi_content.processed }} />}

          {url && (
            <IconButton color="primary" size="large" className={style.button} >
              <EastIcon fontSize="inherit"/>
            </IconButton>
          )}
        </Box>

      </Grid>
    </>
  )
}
