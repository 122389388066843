import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";
import {
  BaseSyntheticEvent,
  ReactElement,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { REGION_PATHS } from "./constants";
import { theme } from "@styles/mui/theme";
import PugliaSystemsMap from "./regions/PugliaSystemsMap";
import PiemonteSystemsMap from "./regions/PiemonteSystemsMap";
import LombardiaSystemsMap from "./regions/LombardiaSystemsMap";
import LazioSystemsMap from "./regions/LazioSystemsMap";
import CampaniaSystemsMap from "./regions/CampaniaSystemsMap";
import { ItRegions } from "./models/ItRegions";
import useWindowSize from "@hooks/useWindowSize";

const REGION_DETAILS: Record<
  ItRegions,
  { label: string; mapComponent: ReactElement }
> = {
  abruzzo: {
    label: "abruzzo",
    mapComponent: <></>,
  },
  basilicata: {
    label: "basilicata",
    mapComponent: <></>,
  },
  calabria: {
    label: "calabria",
    mapComponent: <></>,
  },
  campania: {
    label: "campania",
    mapComponent: (
      <CampaniaSystemsMap
        iconsData={[
          {
            label: "Caivano (NA) - Biotech",
            href: "/impianti/biometano/caivano-biotech"
          },
          { label: "Gricignano di Aversa (CE)" },
          { label: "Caivano (NA)" },
        ]}
      />
    ),
  },
  emiliaRomagna: {
    label: "emiliaRomagna",
    mapComponent: <></>,
  },
  friuliVeneziaGiulia: {
    label: "friuliVeneziaGiulia",
    mapComponent: <></>,
  },
  lazio: {
    label: "lazio",
    mapComponent: (
      <LazioSystemsMap iconsData={[{ label: "Civitavecchia (RM)" }]} />
    ),
  },
  liguria: {
    label: "liguria",
    mapComponent: <></>,
  },
  lombardia: {
    label: "lombardia",
    mapComponent: (
      <LombardiaSystemsMap
        iconsData={[
          {
            label: "Zinasco (PV)",
            href: "/impianti/biometano/zinasco/"
           },
          { label: "Alzano Lombardo (BG)" },
          {
            label: "Cesano Boscone (MI)",
            href: "/impianti/teleriscaldamento/cesano-boscone/"
          },
        ]}
      />
    ),
  },
  marche: {
    label: "marche",
    mapComponent: <></>,
  },
  molise: {
    label: "molise",
    mapComponent: <></>,
  },
  piemonte: {
    label: "piemonte",
    mapComponent: (
      <PiemonteSystemsMap
        iconsData={[
          { label: "Alpignano (TO)" },
          { label: "Barge (CN)" },
          { label: "Cerialdo (CN)" },
          { label: "Vernante (CN)" },
          { label: "Busca (CN)" },
          { label: "Ciriè (TO)" },
        ]}
      />
    ),
  },
  puglia: {
    label: "puglia",
    mapComponent: (
      <PugliaSystemsMap
        iconsData={[
          { label: "Taranto (TA)", href: "/impianti/idrogeno/puglia-green-hydrogen-valley" },
          { label: "Brindisi (BR)", href: "/impianti/idrogeno/puglia-green-hydrogen-valley" }
        ]}
      />
    ),
  },
  sardegna: {
    label: "sardegna",
    mapComponent: <></>,
  },
  sicilia: {
    label: "sicilia",
    mapComponent: <></>,
  },
  toscana: {
    label: "toscana",
    mapComponent: <></>,
  },
  trentinoAltoAdige: {
    label: "trentinoAltoAdige",
    mapComponent: <></>,
  },
  umbria: {
    label: "umbria",
    mapComponent: <></>,
  },
  valleDAosta: {
    label: "valleDAosta",
    mapComponent: <></>,
  },
  veneto: {
    label: "veneto",
    mapComponent: <></>,
  },
};

type Props = {
  isMobile: boolean;
  openedRegion: boolean;
  openRegion: (event: BaseSyntheticEvent) => void;
  deselectRegion: () => void;
  closeRegion: () => void;
  selectedRegion: ItRegions | null;
};

const MapGrid = ({
  isMobile,
  openRegion,
  closeRegion,
  deselectRegion,
  openedRegion,
  selectedRegion,
}: Props) => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const isMediumBrDown = useMediaQuery(theme.breakpoints.down("md"));
  const [maxSvgHeight, setMaxSvgHeight] = useState<null | string>(null);
  const [maxSvgWidth, setMaxSvgWidth] = useState<null | string>(null);

  const size = useWindowSize();

  useLayoutEffect(() => {
    if (!mapContainerRef.current) return;
    const containerHeight =
      mapContainerRef.current?.getBoundingClientRect()?.height;
    const containerWidth =
      mapContainerRef.current?.getBoundingClientRect()?.height;
    setMaxSvgHeight(`${Math.floor((containerHeight / 100) * 70)}px`);
    setMaxSvgWidth(`${Math.floor((containerWidth / 100) * 65)}px`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapContainerRef.current, size, openedRegion]);

  return (
    <Grid
      ref={mapContainerRef}
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "40px",
        fill: "#fff",
        stroke: "#8FB4E2",
        background: isMobile
          ? "linear-gradient(90deg, rgba(227,239,249,1) 15%, rgba(227,239,249,0) 65%)"
          : "none",
        "& path.selectable:hover": {
          fill: "#05196B",
          cursor: "pointer",
        },
        "& > svg": {
          filter: "drop-shadow(-9px 10px 10px  rgb(0 0 0 / 0.1))",
          maxWidth: isMobile ? "none" : "500px",
          width: "100%",
        },
        // minHeight: isMobile ? "70vh" : "auto",
      }}
      item
      xs={6}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 399.2 473.87">
        <g>
          {REGION_PATHS.map(({ id, path, selectable }) => (
            <path
              key={id}
              id={id}
              className={selectable ? "selectable" : ""}
              fill={selectable ? "#6C94D7" : "inherit"}
              strokeMiterlimit="10"
              stroke="inherit"
              d={path}
              {...(selectable && { onClick: openRegion })}
            />
          ))}
        </g>
      </svg>

      <Popover
        id="opened-region"
        onClose={deselectRegion}
        anchorEl={mapContainerRef.current}
        container={mapContainerRef.current}
        open={openedRegion}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        slotProps={{
          root: () => ({
            sx: { position: "absolute", padding: "32px 48px", zIndex: 2 },
          }),
          paper: {
            sx: {
              position: "absolute",
              top: "6% !important",
              left: "10%  !important",
              width: "80%",
              height: "88%",
              borderRadius: "1rem",
              overflow: "hidden",
            }
          }
        }}
        hideBackdrop
        disableScrollLock
      >
        <Box width="100%">
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingY={isMediumBrDown ? "0.6rem" : "1rem"}
            paddingX={isMediumBrDown ? "0.6rem" : "1rem"}
            position="relative"
          >
            <Typography
              fontSize={isMediumBrDown ? "1rem" : "1.5rem"}
              textAlign="center"
              width="100%"
              fontWeight={500}
              color={theme.palette.secondary.main}
              textTransform="uppercase"
            >
              {REGION_DETAILS[selectedRegion]?.label}
            </Typography>

            <IconButton sx={{ padding: 0 }} size="large" onClick={closeRegion}>
              <CloseIcon
                sx={{
                  fontSize: isMediumBrDown ? "1.5rem" : "2.5rem",
                  color: theme.palette.primary.main,
                  stroke: "none",
                }}
              />
            </IconButton>
          </Box>

          <Box
            sx={{
              height: "100%",
              "& svg, & img": {
                height: maxSvgHeight,
                maxHeight: maxSvgHeight, //isMediumBrDown ? "20rem" : "30rem",
                maxWidth: maxSvgWidth,
              },
              "& g, & a": {
                pointerEvents: "bounding-box",
                "&:hover": {
                  cursor: "pointer",
                },
              },
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding={isMobile ? "0.3rem" : "0.4rem"}
          >
            {REGION_DETAILS[selectedRegion]?.mapComponent}
          </Box>
        </Box>
      </Popover>
    </Grid>
  );
};

export default MapGrid;
