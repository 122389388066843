/* eslint-disable */
import React, { useEffect } from "react";

export default function Fiore(props: any) {

  useEffect(() => {
    // @ts-ignore
    (function(s,i,u,o,c,w,d,t,n,x,e,p,a,b){(a=document.getElementById(i.root)).svgatorPlayer={ready:(function(a){b=[];return function(c){return c?(b.push(c),a.svgatorPlayer):b}})(a)};w[o]=w[o]||{};w[o][s]=w[o][s]||[];w[o][s].push(i);e=d.createElementNS(n,t);e.async=true;e.setAttributeNS(x,'href',[u,s,'.','j','s','?','v','=',c].join(''));e.setAttributeNS(null,'src',[u,s,'.','j','s','?','v','=',c].join(''));p=d.getElementsByTagName(t)[0];p.parentNode.insertBefore(e,p);})('91c80d77',{"root":"esEIhxLCiVk1","version":"2022-05-04","animations":[{"elements":{"esEIhxLCiVk2":{"stroke-dashoffset":[{"t":0,"v":2306.578323},{"t":4000,"v":0}]}},"s":"MDNA1ZDk1M2M3ZQThmOGM3YjhlTODM4OTg4M2MA1NDRlNGE0YTNRhUjQ2M2M3ZOTgzOGM3ZjdkQOGU4Mzg5ODgGzYzU0NGI0NjCNjODM4ZTdmOBGM3YjhlODM4YOTg4OGQzYzUN0QTRiNDYzYzMgwODM4Njg2ME2M1NDRiNDYzQY1k3Yjg2OGVGDN2Y4Yzg4N2TI4ZTdmM2NLNETQ4MDdiODY4JZDdmNDYzYzhTkOGE3ZlI3ZjMdlM2M1NDRiTGjQ2M2M4MDhhUOGQzYzU0NGIH0YTRhOTc/"}],"options":"MDLAxMDgyMjk3YRTdiNjg3OTdiIQzI5NDEyOTcTzNzY2ODZiMjCk4NA|"},'https://cdn.svgator.com/ply/','__SVGATOR_PLAYER__','2022-05-04',window,document,'script','http://www.w3.org/2000/svg','http://www.w3.org/1999/xlink')
  }, [])

  return (
    <svg id="esEIhxLCiVk1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 642.6 310" shapeRendering="geometricPrecision" textRendering="geometricPrecision"><defs><linearGradient id="esEIhxLCiVk2-stroke" x1="0" y1="0.5" x2="1" y2="0.5" spreadMethod="pad" gradientUnits="objectBoundingBox" gradientTransform="translate(0 0)"><stop id="esEIhxLCiVk2-stroke-0" offset="0%" stopColor="#b8d6ec"/><stop id="esEIhxLCiVk2-stroke-1" offset="100%" stopColor="rgba(184,214,236,0.15)"/></linearGradient></defs><path id="esEIhxLCiVk2" d="M1,280.8c14.4,5.5,52.1,16.1,87.6,15.2c44.4-1.2,73-7.6,94.6-7.6s74.7,7,94-4.1s21.6-52,21-63.1-5.8-63.7-49-68.9c-15.8-1.9,14,23.4,33.3,7.6-8.6-10.7-28.3-33.5-38.5-39.1-12.8-7-30.4-13.4-40.3-14s-12.8,4.1-25.1,0-15.8-3.5-10.5,5.3s19.3,39.1,35.6,47.9s49.7,20.4,67.1,21c17.5.6,21.6,6.4,33.9-11.1s7-71.8,3.5-74.8c-3.5-2.9-10.6,19.3-3.5,33.9s9.3,22.2,15.2,0c5.8-22.2,11.7-61.3,0-82.4-9.3-16.8-14-37.4-15.2-45.6-7.4,12.1-22.2,41.5-22.2,62.5c0,26.3,9.3,65.4,15.8,77.1c6.4,11.7,10.5,21,25.7,25.1s42.6-16.4,54.3-25.1c11.7-8.8,1.2-24.5-12.3-6.4-13.4,18.1-9.9,26.3,12.3,18.1s73-43.2,83.5-66c8.4-18.2,16.7-39.9,19.8-48.5-9.7,2.7-37,13.1-68.3,32.7-39.1,24.5-59.5,64.2-61.9,73-2.3,8.8-4.7,28-20.4,37.4-15.8,9.3-32.1,5.3-29.2,30.4s12.3,75.3,9.3,84.7c-2.9,9.3-18.7,23.9-15.8,0s34.4-15.2,52.5-15.2s64.2,7,82.3,11.1s32.1,7.6,63.1,0c30.9-7.6,19.8-14.6,81.1-11.1c49,2.8,66-2.3,68.3-5.3" fill="none" stroke="url(#esEIhxLCiVk2-stroke)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDashoffset="2306.578323" strokeDasharray="2306.578323"/>
    </svg>
  );
}
