import parse, { DOMNode, HTMLReactParserOptions } from 'html-react-parser';

export const renderRichText = (text: string) => parse(text, options);

const options: HTMLReactParserOptions = {
  // TODO: Implementare componenti per richtext
  replace: (domNode: Extract<DOMNode, { children } | { type: 'text' }>) => {

    if (domNode.type === 'text') {
      const { parent } = domNode;

      if (parent?.type === 'tag') {
        return domNode.data
      }

      return null;
    }

    const { name, attribs, children } = domNode;
  },
};