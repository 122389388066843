import React, { useRef, useState } from 'react';

import Box from '@mui/material/Box';

import Fab from '@mui/material/Fab';
import Grow from '@mui/material/Grow';

import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/system/useTheme';

import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';

import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';

import FadeIn from '@components/Common/FadeIn/FadeIn';
import { ParagraphProps } from '@model/paragraphs.model';

import Row from '@components/Modules/Utils/Row/Row';
import { Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import SlideContent from './SlideContent';
import styles from './Slider.module.scss';
import { SlideItemProps, SliderProps } from './types';

const Slider: React.FC<ParagraphProps<SliderProps>> = ({ fieldData }) => {
  const { field_slider_layout: layout, field_slider_item: items } = fieldData;

  const swiperRef = useRef(null);
  const [index, setIndex] = useState<number>(0);
  const isMobile: boolean = useMediaQuery(useTheme().breakpoints.down('lg'));

  const slidesPerView = isMobile ? (layout === 'layout-2' ? 1.3 : 1) : (layout === 'layout-2' ? 3 : 1.3);

  const COMMON_SWIPER_PARAMS: SwiperProps = {
    draggable: true,
    simulateTouch: true,
    allowTouchMove: true,
    touchMoveStopPropagation: true,
    pagination: {
      clickable: true
    },
    modules: [Pagination, Navigation],
    onBeforeInit: (swiper) => {
      swiperRef.current = swiper;
      setIndex(swiper.activeIndex);
    },
    onSlideChange: (swiper) => {
      setIndex(swiper.realIndex);
    },
  };

  const MOBILE_SWIPER_PARAMS: SwiperProps = {
    ...COMMON_SWIPER_PARAMS,
    slidesPerView,
    spaceBetween: 12,
  };

  const SWIPER_PARAMS: SwiperProps = {
    ...COMMON_SWIPER_PARAMS,
    slidesPerView,
    spaceBetween: 18,
  };

  if (!items || !items.length) return;

  const prevSlide: () => void = () => swiperRef.current?.slidePrev();
  const nextSlide: () => void  = () => swiperRef.current?.slideNext();

  return (
    <FadeIn>
      <Box className={styles.overflowWrap} >        
        {isMobile ? (
          <Container maxWidth={false} sx={{overflow: 'visible'}}>
            <Grow in={index !== 0} timeout={300}>
              <Fab color='primary' onClick={prevSlide} sx={{ position: 'absolute', top: '35%', left: '8%' }}>
                <ArrowBack />
              </Fab>
            </Grow>

            <Swiper
              ref={swiperRef}
              className={`${styles.SwiperSlider} ${styles.MobileCarousel} ${layout}`}
              {...MOBILE_SWIPER_PARAMS}
            >
              {items.map((slide) => (
                <SwiperSlide key={slide.id} className={`${styles.swiperItem}`}>
                  <SlideContent slide={slide} />
                </SwiperSlide>
              ))}
            </Swiper>

            <Grow in={index !== items.length - 1} timeout={300}>
              <Fab color='primary' onClick={nextSlide} sx={{ position: 'absolute', top: '35%', right: '8%' }}>
                <ArrowForward />
              </Fab>
            </Grow>
          </Container>
        ) : (
          <Row justifyContent='center'>
            <Grid item lg={layout === 'layout-2' ? 1 : 2} display='flex' alignItems='center' justifyContent='flex-end'>
              <Grow in={index !== 0} timeout={300}>
                <Fab color='primary' onClick={prevSlide}>
                  <ArrowBack />
                </Fab>
              </Grow>
            </Grid>

            <Grid item xs={12} lg={layout === 'layout-2' ? 10 : 8}>
              <Swiper ref={swiperRef} {...SWIPER_PARAMS} className={`${styles.SwiperSlider} ${layout}`}>
                {items?.map((slide: SlideItemProps) => {
                  return (
                    <SwiperSlide key={slide.id} className={`${styles.swiperItem}`}>
                      <SlideContent slide={slide} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Grid>

            <Grid item lg={layout === 'layout-2' ? 1 : 2} display='flex' alignItems='center' justifyContent='flex-start'>
              <Grow in={index < items.length - slidesPerView} timeout={300}>
                <Fab color='primary' onClick={nextSlide}>
                  <ArrowForward />
                </Fab>
              </Grow>
            </Grid>

          </Row>
        )}

      </Box>
    </FadeIn>
  );
};

export default Slider;
