import { useState } from "react";

export const useTooltipsControl = () => {
  const [opened, setOpened] = useState<string | null>(null);
  const toggle = (id?: string) => {
    if (!id) {
      setOpened(null);
      return;
    }
    setOpened(id);
  };
  return {opened, toggle};
};

export default useTooltipsControl;