import { ParagraphProps } from '@model/paragraphs.model';

import { DrupalSimpleTextField } from '@model/fields.model';

interface AnchorProps {
  field_id: DrupalSimpleTextField;
}

const Anchor: React.FC<ParagraphProps<AnchorProps>> = ({ fieldData }) => {
  const { field_id } = fieldData;
  return <div id={field_id ? field_id : fieldData.id} />;
}

export default Anchor