import { useEffect, useRef, useState } from "react";
import Fade from "@mui/material/Fade";
import useOnScreen from "@hooks/useOnScreen";

export default function Title({fieldData}) {
  const ref: any = useRef(null);
  const onScreen: boolean = useOnScreen(ref);
  const [loaded, setLoadead] = useState(false);

  useEffect(()=> {
    if(onScreen) setLoadead(true);
  }, [onScreen])
  return (
    <Fade ref={ref} in={loaded} appear={false} timeout={1000} style={{transitionDelay: '100ms'}}>
      <div dangerouslySetInnerHTML={{ __html: fieldData?.field_title }} />
    </Fade>
  )
}
