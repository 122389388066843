
import { useTranslation } from "next-i18next";

import { DrupalNode, DrupalTaxonomyTerm } from "next-drupal";

import ArticleCardCarousel from "@components/Modules/NextJournal/ArticleCardCarousel/ArticleCardCarousel";
import ArticleCardGrid from "@components/Modules/NextJournal/ArticleCardGrid/ArticleCardGrid";
import ContentHeader from "@components/Modules/NextJournal/ContentHeader/ContentHeader";
import East from "@mui/icons-material/East";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/system/useTheme";
import Link from "next/link";
import { ArticleCardVariant } from "../ArticleCard";
import { isCardComplete } from "../constants";

export interface CategoryCardsSectionProps extends DrupalTaxonomyTerm { 
  articles: DrupalNode[],
  i?: number;
};

const CategoryCardsSection: React.FC<CategoryCardsSectionProps> = (props) => {
  const { name, path, articles, i } = props;
  const { t } = useTranslation();
  const isMobile: boolean = useMediaQuery(useTheme().breakpoints.down("lg"));
  
  const cardVariant: ArticleCardVariant = isCardComplete(name) ? 'complete': 'default'
  return (
    <>
      {articles?.length > 0 && (
        <Box>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ pt: { xs: 20, lg: i === 0 ? "16vh" : 0 }  }}>
            <Box flex={1}>
              <ContentHeader isMobile={isMobile} title={name} />
            </Box>

            <Stack
              component={Link}
              href={path?.alias || '/next-journal'}
              direction={{xs: "row-reverse", lg: "row"}}
              alignItems="center"
              justifyContent="flex-end"
              flex={{xs: 0, lg: 1}}
              spacing={8}
            >
              <East />
              <Typography sx={{ color: "secondary" }} >
                {t("next-journal.index.readAll")}
              </Typography>
            </Stack>
  
          </Stack>

          {isMobile ? <ArticleCardCarousel cardVariant={cardVariant} articles={articles} /> : <ArticleCardGrid cardVariant={cardVariant} articles={articles} /> }
        </Box>
      )}
    </>
  );
};

export default CategoryCardsSection;
