/* eslint-disable */
import React, { useEffect } from "react";

export default function TrackMobile(props: any) {

  useEffect(() => {
    // @ts-ignore
    (function(s,i,u,o,c,w,d,t,n,x,e,p,a,b){(a=document.getElementById(i.root)).svgatorPlayer={ready:(function(a){b=[];return function(c){return c?(b.push(c),a.svgatorPlayer):b}})(a)};w[o]=w[o]||{};w[o][s]=w[o][s]||[];w[o][s].push(i);e=d.createElementNS(n,t);e.async=true;e.setAttributeNS(x,'href',[u,s,'.','j','s','?','v','=',c].join(''));e.setAttributeNS(null,'src',[u,s,'.','j','s','?','v','=',c].join(''));p=d.getElementsByTagName(t)[0];p.parentNode.insertBefore(e,p);})('91c80d77',{"root":"eiUKuFA2HGW1","version":"2022-05-04","animations":[{"elements":{"eiUKuFA2HGW2":{"stroke-dashoffset":[{"t":0,"v":1559.883336},{"t":2000,"v":0}]}},"s":"MDOA1M2JmNjZhOYGI5YjZhNWI4RYWRiM2IyQjYC2N2U3Njc0NzKRWNzQ3MDY2YBThhZGI2SGE5WYTdiOGFkYjNRiMjY2N2U3NTWcwNjZhZGI4RS2E5YjZhNWI4FYWRiM2IyYjcF2NjdlNzVMNzBA2NmFhTWFkYHjBiMDY2N2VFHNzU3ME82NmEH1SmIwYjhYYTPliNmIyYTViOIGE5NjZTN2VhBYWE1YjBiN2EP5NzA2NmI3YjGRhOWE5YThUNAjY3ZTc1YzE/R"}],"options":"MDAAxZTljNDM5NYDk1ODI5Mzk1INDM1YjQzOTQY4NDkzOTA4ZDAhkNDNINGQ0MDzk0ODQ5M0s5LMDhkOGQ0MzVNiNTM1Njll"},'https://cdn.svgator.com/ply/','__SVGATOR_PLAYER__','2022-05-04',window,document,'script','http://www.w3.org/2000/svg','http://www.w3.org/1999/xlink')
  }, [])

  return (
    <svg id="eiUKuFA2HGW1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194 897" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" preserveAspectRatio="none"><defs><linearGradient id="eiUKuFA2HGW2-stroke" x1="-135" y1="-32.0001" x2="-296.6" y2="39.4739" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)"><stop id="eiUKuFA2HGW2-stroke-0" offset="0%" stop-color="#b8d6ec"/><stop id="eiUKuFA2HGW2-stroke-1" offset="27.8328%" stop-color="#e0effa"/><stop id="eiUKuFA2HGW2-stroke-2" offset="98.3711%" stop-color="#fff"/></linearGradient></defs><path id="eiUKuFA2HGW2" d="M-50,6.19416C-25,-0.805932,84.8,-6.20594,96,28.1941s-40,100.6669-72,130.9999c-24.666666,22-46.6623,43.816,13,82c25,16,41,25,59,37c28.878,19.252,36.235,62.803-28,98-73,40-67.22068,62.39-44,74c18,9,66.8,24,82,56s-48.3333,102-82,133c-15,13.812-29,43.834,0,68c38.4,32,99.667,17.473,143,55.806c51,40,47,106-236,133" fill="none" stroke="url(#eiUKuFA2HGW2-stroke)" stroke-width="2" stroke-dashoffset="1559.883336" stroke-dasharray="1559.883336"/>
    </svg>
  );
}