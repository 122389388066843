import React from "react";

export default function OndeBottom() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 1400 407"
    >
      <path
        fill="url(#paint0_linear_787_24171)"
        fillOpacity="0.5"
        d="M165.4 388.026c-72.383.937-140.421-48.503-165.392-73.34V.005H1400.01v340.924s-70.05 14.059-171.72 14.059c-221.34 0-401.806-62.327-577.414-63.733-175.608-1.406-394.996 95.599-485.476 96.771z"
      ></path>
      <path
        fill="url(#paint1_linear_787_24171)"
        fillOpacity="0.8"
        d="M190.696 373.498C100.611 347.876 24.979 342.022.008 340.929V.005H1400.01V350.77c-44.59 16.089-159.11-40.765-238.5-40.765-135.72 0-208.87 40.765-312.002 30.924-293.455-28.002-393.211 66.076-495.852 66.076-66.157 0-111.883-18.979-162.96-33.507z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_787_24171"
          x1="700.008"
          x2="700.008"
          y1="388.039"
          y2="229.997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0EFFA"></stop>
          <stop offset="1" stopColor="#fff"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_787_24171"
          x1="700.008"
          x2="700.008"
          y1="407.005"
          y2="173.965"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0EFFA"></stop>
          <stop offset="1" stopColor="#fff"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}