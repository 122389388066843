import { SvgIcon, SvgIconProps } from "@mui/material";

const IconBiometano = ({ stroke, ...otherProps }: { stroke: string } & SvgIconProps) => {
  return (
    <SvgIcon
      width="30"
      height="30"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <defs>
        <clipPath id="clip0_503_33170">
          <rect
            width="30"
            height="29.8224"
            fill="white"
            transform="translate(0 0.0878906)"
          />
        </clipPath>
      </defs>

      <g 
        fill="none"
        clipPath="url(#clip0_503_33170)"
      >
        <path
          d="M28.1222 17.4514C28.1222 17.4514 29.3741 19.2721 29.3741 21.8328C29.3741 24.8849 27.7733 27.7008 24.3668 28.7179C24.4028 24.3279 21.4539 25.4107 19.9854 21.8328C17.4277 24.0861 17.5248 26.1383 17.4817 28.7179C15.3552 28.0763 13.1003 26.2338 13.1003 22.4588C13.1003 19.2627 15.604 16.8826 15.604 14.3219C15.604 14.3219 17.7352 14.7178 18.1076 17.4514C20.2076 15.1911 20.6113 13.1272 20.6113 10.5664C24.0968 12.7892 24.9927 15.0612 24.9927 19.3292C26.4143 19.0514 27.1826 18.4459 28.1222 17.4514Z"
          stroke={stroke || "#4F9E30"}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.50371 14.3305C3.93784 14.7334 5.17949 14.947 6.2545 14.947C13.1442 14.9478 13.1442 8.68947 13.1442 1.17773C9.92156 3.05704 0.625977 2.40609 0.625977 9.32008C0.625977 10.6775 0.845828 12.7063 2.50371 14.3305Z"
          stroke={stroke || "#4F9E30"}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.6479 3.05469C18.1516 3.05469 20.4863 3.75571 22.4767 4.96998"
          stroke={stroke || "#4F9E30"}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.32622 4.91406C6.06522 6.92324 3.14221 10.4033 2.58514 14.3591C2.50377 14.96 2.45996 15.5734 2.45996 16.1993C2.45996 23.0718 7.73015 28.705 14.4462 29.2871"
          stroke={stroke || "#4F9E30"}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.604 3.05469C22.4452 3.05469 28.0597 8.2748 28.6856 14.9533"
          stroke={stroke || "#4F9E30"}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
};

export default IconBiometano;