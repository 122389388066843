/* eslint-disable */
import React, { useEffect } from "react";

export default function TrackSix(props: any) {

  useEffect(() => {
    // @ts-ignore
    (function(s,i,u,o,c,w,d,t,n,x,e,p,a,b){(a=document.getElementById(i.root)).svgatorPlayer={ready:(function(a){b=[];return function(c){return c?(b.push(c),a.svgatorPlayer):b}})(a)};w[o]=w[o]||{};w[o][s]=w[o][s]||[];w[o][s].push(i);e=d.createElementNS(n,t);e.async=true;e.setAttributeNS(x,'href',[u,s,'.','j','s','?','v','=',c].join(''));e.setAttributeNS(null,'src',[u,s,'.','j','s','?','v','=',c].join(''));p=d.getElementsByTagName(t)[0];p.parentNode.insertBefore(e,p);})('91c80d77',{"root":"empe574TxVn1","version":"2022-05-04","animations":[{"elements":{"empe574TxVn2":{"stroke-dashoffset":[{"t":0,"v":2405.698869},{"t":2000,"v":4811.4}]}},"s":"MDDA1ZGRiODJjNLFFkNWQyYzFkSNGM5Y2ZjZTgEyOWE5MjkwOTYA5MDhjODJjNWGM5ZDJjNWMzUZDRjOVBjZldBjZTgyOWE5MTKhjODJjOUpkNTGM1ZDJNYzFkGNGM5Y2ZjZWQDzODI5YTkxOGHM4MmM2YzljYB2NjODI5YTkxOOGNBODJjMWNCjZDRjNWQyY2WVQYzFkNEhjNATgyOWFjNmMxQY2NkM2M1OGMM4MmQzZDBjNWAM1YzQ4MjlhOHTE4Y0M4MmM2HZDBkMzgyOWES5MTkwOTBkZAE|"}],"options":"MDWAxMDgyMjk3YSTdiNjhHNzk3UYjI5NDEyOTcVzNzY2ODZiMjUk4NA|"},'https://cdn.svgator.com/ply/','__SVGATOR_PLAYER__','2022-05-04',window,document,'script','http://www.w3.org/2000/svg','http://www.w3.org/1999/xlink')
  }, [])

  return (
    <svg id="empe574TxVn1" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1145 504" shapeRendering="geometricPrecision" textRendering="geometricPrecision"><defs><linearGradient id="empe574TxVn2-stroke" x1="933.3455" y1="-17.9281" x2="-30.0734" y2="626.29" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)"><stop id="empe574TxVn2-stroke-0" offset="0%" stopColor="#b8d6ec"/><stop id="empe574TxVn2-stroke-1" offset="49.45%" stopColor="#e0effa"/><stop id="empe574TxVn2-stroke-2" offset="100%" stopColor="#fff"/></linearGradient></defs><path id="empe574TxVn2" d="M0,467c148,0,129-109,273-37s263,109,471,23c232.2-96,250-40,328-76c32-14.8,116-93,42-214C1055.1,66.7,1000,1,862,1C756,1,608,83,536,99C446,119,318,1,174,1" fill="none" stroke="url(#empe574TxVn2-stroke)" strokeWidth="2" strokeDashoffset="2405.698869" strokeDasharray="2405.698869"/></svg>
  );
}