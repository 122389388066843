import React from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Row from "@components/Modules/Utils/Row/Row";

import styles from './Text.module.scss';

import FadeIn from '@components/Common/FadeIn/FadeIn';
import { ParagraphProps } from '@model/paragraphs.model';

interface FieldData {
  field_text_title: string;
  field_text_content: any;
}

const Text: React.FC<ParagraphProps<FieldData>> = ({ fieldData: {field_text_title: title, field_text_content} }) => {

  const htmlContent = field_text_content?.processed;
  
  return (
    <FadeIn>
      <Container maxWidth={false} className={styles.text}>
        <Row justifyContent="center">
          <Grid item xs={12} lg={10}>
            {title && <Typography variant="h1" className={styles.textTitle}>{title}</Typography>}
            {htmlContent && <Typography variant="bodyL" component="div" className={styles.textContent} dangerouslySetInnerHTML={{__html: htmlContent}} />}
          </Grid>
        </Row>
      </Container>
    </FadeIn>
  )
}

export default Text