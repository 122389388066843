import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { BaseSyntheticEvent, ReactElement } from "react";
import { theme } from "@styles/mui/theme";
import { ItRegions } from "./models/ItRegions";
import IconBiometano from "@components/UI/Icons/IconBiometano";
import IconDistrictHeating from "@components/UI/Icons/IconDistrictHeating";
import IconHydrogen from "@components/UI/Icons/IconHydrogen";
import { useTranslation } from "next-i18next";

const REGION_SYSTEMS: {
  id: ItRegions;
  label: string;
  icons: { id: string; icon: ReactElement; value: number }[];
}[] = [
  {
    id: "lombardia",
    label: "Lombardia",
    icons: [
      {
        id: "biometano",
        icon: <IconBiometano stroke="#4F9E30" />,
        value: 1,
      },
      {
        id: "districtHeating",
        icon: <IconDistrictHeating stroke="#4F9E30" />,
        value: 2,
      },
    ],
  },
  {
    id: "piemonte",
    label: "Piemonte",
    icons: [
      {
        id: "districtHeating",
        icon: <IconDistrictHeating stroke="#4F9E30" />,
        value: 6,
      },
    ],
  },
  {
    id: "puglia",
    label: "Puglia",
    icons: [
      {
        id: "hydrogen",
        icon: <IconHydrogen fill="#4F9E30" stroke="#4F9E30" />,
        value: 2,
      },
    ],
  },
  {
    id: "campania",
    label: "Campania",
    icons: [
      {
        id: "biomeatano",
        icon: <IconBiometano stroke="#4F9E30" />,
        value: 3,
      },
    ],
  },
  {
    id: "lazio",
    label: "Lazio",
    icons: [
      {
        id: "biomeatano",
        icon: <IconBiometano stroke="#4F9E30" />,
        value: 1,
      },
    ],
  },
];

type Props = {
  isMobile: boolean;
  openRegion: (event: BaseSyntheticEvent) => void;
};

const MapLegend = ({ isMobile, openRegion }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "stretch",
      }}
      item
      xs={4}
    >
      <Box height="100%" width="100%" paddingY={50} paddingX={20}>
        <Box width="100%" height={isMobile ? "auto" : "70%"}>
          <Box
            sx={{
              marginBottom: theme.spacing(12),
              fontWeight: 500,
              textTransform: "uppercase",
            }}
          >
            <Typography
              variant="bodyL"
              sx={{
                fontWeight: 500,
                textTransform: "uppercase",
              }}
            >
              {t("paragraphs.italyMap.title")}
            </Typography>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="start"
            rowGap={40}
            paddingBottom={20}
            flexWrap="wrap"
            width="100%"
          >
            {REGION_SYSTEMS.map(({ id, label, icons }) => (
              <Box key={id} width="50%" height="fit-content">
                <Typography
                  variant="bodyM"
                  id={id}
                  sx={{
                    display: "inline-block",
                    marginBottom: theme.spacing(6),
                    fontWeight: 500,
                    textTransform: "uppercase",
                    cursor: "pointer",
                  }}
                  onClick={openRegion}
                >
                  {label}
                </Typography>

                <Box display="flex" alignItems="start" gap={16}>
                  {icons.map((el) => (
                    <Box key={el.id} display="flex" alignItems="start">
                      <Typography
                        variant="bodyL"
                        sx={{ mr: theme.spacing(3), textAlign: 0, fontWeight: 400, fontSmooth: '5em' }}
                      >
                        {el.value}
                      </Typography>

                      {el.icon}
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        <Box height={isMobile ? "auto" : "30%"} width="100%">
          <Divider sx={{ width: "50%", mb: theme.spacing(10) }} />

          <Typography
            sx={{ display: "inline-block", mb: theme.spacing(18) }}
            variant="bodyS"
          >
            {t("paragraphs.italyMap.legend")}
          </Typography>

          <Box display="flex" alignItems="center" marginBottom={16}>
            <IconBiometano stroke="#4F9E30" />
            <Typography variant="bodyS" sx={{ ml: theme.spacing(8) }}>
              {t("paragraphs.italyMap.biomethane")}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" marginBottom={16}>
            <IconDistrictHeating stroke="#4F9E30" />
            <Typography variant="bodyS" sx={{ ml: theme.spacing(8) }}>
              {t("paragraphs.italyMap.districtHeating")}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center">
            <IconHydrogen fill="#4F9E30" stroke="#4F9E30" />
            <Typography variant="bodyS" sx={{ ml: theme.spacing(8) }}>
              {t("paragraphs.italyMap.hydrogen")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default MapLegend;
