import formatFormSchema from '@helper/form/formatFormSchema';
import useOnScreen from '@hooks/useOnScreen';
import Image from "next/legacy/image";
import { NextRouter, useRouter } from "next/router";
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';

import Row from "@components/Modules/Utils/Row/Row";

import styles from './EditorialSplit.module.scss';

import componentMapper from "@data-driven-forms/mui-component-mapper/component-mapper";
import { FormRenderer } from "@data-driven-forms/react-form-renderer";
import CustomFormTemplate from "@components/Form/Template/CustomFormTemplate";

import Loader from '@components/Common/Loader/Loader';
import { sendFormData } from "@helper/formData";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import EnhancedSelect from '@components/Form/Fields/EnhancedSelect';
import FileUploadComponent, { fileSizeValidator } from '@components/Form/Fields/InputUpload';
import PlainText from '@components/Form/Fields/PlainText';
import { getThumbnailAlt, getThumbnailUrl, getVideoUrl } from '@helper/media';
import { DrupalMediaField, DrupalTextField } from '@model/fields.model';
import { ParagraphProps } from '@model/paragraphs.model';

interface EditorialSplitProps {
  field_background_preset: 'gray_light' | 'white';
  field_title: string;
  field_description: DrupalTextField;
  field_immagine: DrupalMediaField;
  field_text_title: string;
  field_form_item_id: string;
  field_form_item_typ_title: string;
  field_form_item_typ_message: string;
}

export const EditorialSplit: React.FC<ParagraphProps<EditorialSplitProps>> = ({ fieldData, index }) => {

  const {field_title, field_description, field_immagine, field_text_title, field_form_item_id, field_form_item_typ_title, field_form_item_typ_message} = fieldData
  const router: NextRouter = useRouter();

  const ref: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const refVideo: MutableRefObject<HTMLVideoElement | null> = useRef(null);

  const onScreen: boolean = useOnScreen(ref);
  const isMobile: boolean = useMediaQuery(useTheme().breakpoints.down("lg"));
  
  const [loader, setLoader] = useState<boolean>(false);

  const [data, setData] = useState(null)
  const [schema, setSchema] = useState(null)

  useEffect(() => {
    if (!field_form_item_id) return;
    
    const fetchData = async () => {
      const response = await fetch(`/api/hubspot/getData/${field_form_item_id}`);
      const data = await response.json();
      setData(data);
      setSchema(formatFormSchema(data, router));
    };
  
    fetchData();
  }, [field_form_item_id, router]);

  const validatorMapper = { 'file-size': fileSizeValidator, };
  const customComponentMapper = { ...componentMapper, 'file-upload': FileUploadComponent, 'enhanced-select': EnhancedSelect, 'plain-text': PlainText };

  const submitForm = async (values) => {
    const response = await sendFormData(field_form_item_id, values, router);
    const isEts: boolean = field_form_item_id === 'd5c7af3a-a358-4235-845d-3a222e3e297b';

    if(response.status === 200) {
      router.push({
        pathname: '/thankyou',
        query: {
          message: encodeURIComponent('Riceverai una mail con il materiale richiesto il prima possibile'),
          // TODO: Rimuovere non appena le configurazioni di drupal salgono su prod
          ...(isEts && {
            title: encodeURIComponent("Grazie per la registrazione"),
            message: encodeURIComponent("Riceverai un’email con le istruzioni per partecipare all’evento"),
          }),
          ...((field_form_item_typ_title || field_form_item_typ_message) && {
            title: encodeURIComponent(field_form_item_typ_title),
            message: encodeURIComponent(field_form_item_typ_message),
          }),
        } 
      });
    }
  }

  const hasImage: boolean = !!field_immagine?.field_media_image
  const hasVideo: boolean = !!field_immagine?.field_media_video_file

  const imageUrl: string | null = hasImage ? getThumbnailUrl(field_immagine) : null;
  const imageAlt: string | null = hasImage ? getThumbnailAlt(field_immagine) : null;
  const width: number = hasImage ? field_immagine?.field_media_image?.resourceIdObjMeta?.width : null;
  const height: number = hasImage ? field_immagine?.field_media_image?.resourceIdObjMeta?.height : null;

  // TODO: integrare background color
  return (
    <Fade ref={ref} in={onScreen} appear={onScreen} timeout={1000} style={{ transitionDelay: '100ms' }}>

      <Container className={`${styles.editorialSplit}`} maxWidth={false} >
        <div id="contactform" className={styles.contactFormAnchor} />
        <Row spacing={20} sx={{pt: index === 1 ? '18vh' : 0 }}>

          <Grid item lg={6} mb={isMobile ? 55 : 0}>
            <div className={styles.separator} />
            {field_title && <Typography variant="h4" component="div" mb={67} dangerouslySetInnerHTML={{ __html: field_title }} />}

            {field_description?.processed && <Typography variant="bodyL" component="div" dangerouslySetInnerHTML={{ __html: field_description?.processed }} className={styles.textContent} />}
            
            <Box mt={(hasVideo || hasImage) ? 40: 0}>
              {hasImage && <Image src={imageUrl} alt={imageAlt} width={width} height={height} /> }

              {hasVideo && 
                <video ref={refVideo}
                  className={styles.video}
                  autoPlay={false}
                  controls
                  controlsList="nodownload"
                  playsInline
                  style={{width: '100%'}}
                >
                  <source src={getVideoUrl(field_immagine)} type="video/mp4" />
                </video>
              }
            </Box>
          </Grid>

          {!isMobile && <Grid item lg={1} />}

          {schema && 
            <Grid item lg={4}>
                <div className={styles.separator} />
                {field_text_title && <Typography variant="h4" component="div" mb={67} dangerouslySetInnerHTML={{ __html: field_text_title }} />}

                <FormRenderer
                  schema={schema}
                  componentMapper={customComponentMapper}
                  FormTemplate={({ formFields }) => <CustomFormTemplate formFields={formFields} submitLabel={data?.displayOptions?.submitButtonText} />}
                  validatorMapper={validatorMapper}
                  onSubmit={(values) =>{
                    setLoader(true);
                    submitForm(values);
                  }}
                />
              {loader && <Loader active={loader} />}
            </Grid>
          }

        </Row>
      </Container>

    </Fade>
  )
}

export default EditorialSplit