import { SvgIcon, SvgIconProps } from "@mui/material";

const IconHydrogen = ({ stroke, fill, ...otherProps }: { stroke: string; fill: string } & SvgIconProps) => {
  return (
    <SvgIcon
      width="32"
      height="36"
      viewBox="0 0 32 36"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <defs>
        <clipPath id="clip0_503_33181">
          <rect width="32" height="36" fill="white" />
        </clipPath>
      </defs>
      
      <g clipPath="url(#clip0_503_33181)">
        <path
          d="M26.6069 5.39359C23.7736 2.56026 20.0068 1 16.0002 1C11.9937 1 8.22643 2.56026 5.39359 5.39359C2.56026 8.22643 1 11.9932 1 16.0003C1 18.1444 1.44301 20.2155 2.31703 22.1554C2.9668 23.5978 3.82834 24.9103 4.88516 26.073L1.59034 25.8837L1.52641 26.9985L6.80751 27.3016V21.9087H5.69076V25.2964C3.40581 22.7587 2.11675 19.4394 2.11675 15.9998C2.11675 8.3448 8.3448 2.11625 16.0002 2.11625C23.6557 2.11625 29.8837 8.3443 29.8837 15.9998C29.8837 21.6449 26.3842 26.8017 21.1545 28.8949C21.0676 28.5188 20.9253 28.0643 20.6935 27.5953C20.1636 26.5235 19.0533 25.1675 16.7764 24.7969C12.5611 24.1107 8.08209 29.2714 7.8933 29.4912L7.58165 29.8543L7.8933 30.2174C8.07011 30.4236 12.0192 34.9746 15.9868 34.9746C16.25 34.9746 16.5137 34.9546 16.7764 34.9116C19.0533 34.541 20.1636 33.1856 20.6935 32.1133C21.131 31.2282 21.2489 30.3952 21.2804 30.0426C21.3103 30.0311 21.3408 30.0206 21.3708 30.0091C24.1736 28.9338 26.5689 27.0609 28.297 24.5917C30.0655 22.065 31.0005 19.0938 31.0005 15.9993C31.0005 11.9927 29.4402 8.22593 26.6069 5.3926V5.39359ZM19.6926 31.6193C19.0903 32.837 18.049 33.5741 16.5971 33.8104C13.5869 34.3008 10.1433 30.9805 9.07448 29.8548C10.1433 28.729 13.5874 25.4092 16.5971 25.8992C19.1742 26.3187 19.8959 28.2651 20.0977 29.2689C19.6367 29.4113 19.1697 29.5281 18.6972 29.6215C18.3311 29.4183 17.3632 28.8264 16.4143 27.7781L15.5867 28.5273C16.0837 29.0766 16.5831 29.5127 17.0191 29.8468C16.6815 29.8713 16.3419 29.8837 16.0002 29.8837C15.2171 29.8837 14.432 29.8178 13.6664 29.6885L13.4801 30.7892C14.3071 30.9291 15.1552 31 16.0002 31C16.4383 31 16.8733 30.9805 17.3058 30.9436C16.9152 31.2957 16.3668 31.6693 15.6252 31.9315L15.9973 32.9843C17.7433 32.3675 18.6378 31.2183 18.9644 30.7068C19.3445 30.6304 19.7216 30.54 20.0947 30.4341C20.0283 30.7588 19.9089 31.1828 19.6931 31.6188L19.6926 31.6193Z"
          fill={fill || "#4F9E30"}
          stroke={stroke || "#4F9E30"}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.5044 16.0007C27.5044 13.514 26.7238 11.1472 25.2469 9.15538C23.7996 7.20306 21.8168 5.78114 19.5133 5.04297L19.1727 6.10628C23.4884 7.48874 26.3877 11.4648 26.3877 16.0007H27.5044Z"
          fill={fill || "#4F9E30"}
          stroke={stroke || "#4F9E30"}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.06653 8.52734V22.5846H10.1833V16.1144H17.9746V22.5846H19.0913V8.52734H17.9746V14.9976H10.1833V8.52734H9.06653Z"
          fill={fill || "#4F9E30"}
          stroke={stroke || "#4F9E30"}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.339 20.2672C24.9413 19.8591 25.3009 19.1819 25.3009 18.4547C25.3009 17.2475 24.3185 16.2656 23.1118 16.2656H22.5959C21.3893 16.2656 20.4073 17.2475 20.4073 18.4547V18.646H21.5236V18.4547C21.5236 17.8628 22.0046 17.3819 22.5959 17.3819H23.1118C23.7032 17.3819 24.1841 17.8628 24.1841 18.4547C24.1841 18.8093 24.0098 19.1399 23.7167 19.3397L20.4972 21.4334V22.5616H25.1785V21.4453H22.5265L24.3305 20.2727L24.339 20.2672Z"
          fill={fill || "#4F9E30"}
          stroke={stroke || "#4F9E30"}
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
};

export default IconHydrogen;
