
import { useTranslation } from "next-i18next";

import {
  DrupalNode,
} from "next-drupal";

import { East } from "@mui/icons-material";
import { Container, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/system/useTheme";
import { CardVariantModel } from "@components/Modules/Cards";
import ArticleCardCarousel from "@components/Modules/NextJournal/ArticleCardCarousel/ArticleCardCarousel";
import ArticleCardGrid from "@components/Modules/NextJournal/ArticleCardGrid/ArticleCardGrid";
import ContentHeader from "@components/Modules/NextJournal/ContentHeader/ContentHeader";
import { resolveLink } from "@helper/parseHTML";
import Link from "next/link";
import { FC, useEffect, useState } from "react";
import { DrupalLinkField, DrupalMediaField, DrupalTextField } from "@model/fields.model";
import { ParagraphProps } from "@model/paragraphs.model";

interface CardsSelectionProps {
  id: string;
  field_title: string;
  field_link: DrupalLinkField;
  field_items?: ItemProps[]
}

interface ItemProps {
  id: string;
  title: string;
  field_title: string;
  field_link: DrupalLinkField;
  field_description: DrupalTextField;
  field_immagine: DrupalMediaField;
  field_node_reference: DrupalNode;
}

const CardsSelection: FC<ParagraphProps<CardsSelectionProps>> = ({ fieldData, articles }) => {
  const {field_title: title, field_link, field_items} = fieldData;

  const { t } = useTranslation();
  const isMobile: boolean = useMediaQuery(useTheme().breakpoints.down("lg"));
  
  const cardVariant: CardVariantModel = 'implants'
  const titleUrl = resolveLink(field_link)
  
  const [items, setItems] = useState(null);

  useEffect(() => {
    const data = field_items.map(item => {
      const reference: DrupalNode = item?.field_node_reference

      return {
        ...(reference ?? item ),
        type: reference?.type || 'node--implants',
        title: item.field_title || reference?.title,
        field_article_image: item.field_immagine || reference?.field_article_image,
        href: resolveLink(item?.field_link) || reference?.path?.alias || null,
      }
    })

    setItems(data)
  }, [field_items])

  return (
    <Container maxWidth="xl">
      {items?.length ? (
        <Box>
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', pt: 20   }}>

            <Box>
              <ContentHeader isMobile={isMobile} title={title} />
            </Box>

            {titleUrl && !isMobile ? 
              <Link href={titleUrl} >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <East sx={{ mr: "1rem" }} />
                  <Typography sx={{ color: "secondary" }} >
                    {t("next-journal.index.readAll")}
                  </Typography>
                </Box>
              </Link> : null
            }
          </Box>

          {isMobile ? <ArticleCardCarousel cardVariant={cardVariant} articles={items} /> : <ArticleCardGrid cardVariant={cardVariant} articles={items} /> }
        </Box>
      ) : null}
    </Container>
  );
};

export default CardsSelection;
