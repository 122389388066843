import { PhoneInTalk } from '@mui/icons-material';

import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Row from "@components/Modules/Utils/Row/Row";
import useOnScreen from '@hooks/useOnScreen';
import { useEffect, useRef, useState } from 'react';

import styles from './../Download/Download.module.scss'


interface FieldData {
  fieldData: any;
  field_callmenow_title: string;
  field_callmenow_item: any;
}

export default function CallMeNow({ fieldData: {field_callmenow_title, field_callmenow_item} }: FieldData) {
  const ref: any = useRef(null);
  const onScreen: boolean = useOnScreen(ref);
  
  const [loaded, setLoadead] = useState(false);
  useEffect(()=> {
    if(onScreen) setLoadead(true);
  }, [onScreen])

  return (
  <Fade ref={ref} in={loaded} appear={false} timeout={1000} style={{transitionDelay: '100ms'}}>
    <Container maxWidth={false} className={styles.callmenow}>
      <Row justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Row rowSpacing={16}>
            {field_callmenow_title && (
              <Grid item xs={12}>
                <Typography variant="h6">{field_callmenow_title}</Typography>
              </Grid>
            )}

            {field_callmenow_item && field_callmenow_item.map((el, index) => {

              return (
                <Grid item xs={12} lg={6} key={index}>
                  <a className={styles.callmenowItem} href={`tel:${el.field_callmenow_item_numero}`} target="_blank" rel="noreferrer">
                    {el?.field_callmenow_item_label && <Typography variant="bodyL" fontWeight={300}>{el.field_callmenow_item_label}</Typography>}
                    {el.field_callmenow_item_numero && <Typography variant="h6" color="primary" className={styles.callmenowNumber}>{el.field_callmenow_item_numero}</Typography>}
                    <PhoneInTalk color="primary" fontSize="large"/>
                  </a>
                </Grid>
              )
            })}
          </Row>
        </Grid>
      </Row>
    </Container>
  </Fade>
  )
}