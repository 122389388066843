import Image from 'next/image';

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { getThumbnailAlt, getThumbnailUrl } from "@helper/media";

import FadeIn from '@components/Common/FadeIn/FadeIn';
import { ParagraphProps } from '@model/paragraphs.model';
import styles from './Image.module.scss';

interface ImageAsset {
  field_media_image: {
    resourceIdObjMeta: {
      width: number;
      height: number;
    };
  };
}

interface ImageProps {
  field_image_asset: ImageAsset;
}

const ImageParagraph: React.FC<ParagraphProps<ImageProps>> = ({ fieldData }) => {
  const { field_image_asset } = fieldData;
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const url = getThumbnailUrl(field_image_asset);
  const alt = getThumbnailAlt(field_image_asset);
  const { width, height } = field_image_asset?.field_media_image?.resourceIdObjMeta || { width: 0, height: 0 };

  const shouldDisplay = field_image_asset && !isMobile;

  return shouldDisplay ? (
    <FadeIn>
      <div className={styles.imageWrapper}>
        <Image
          src={url}
          alt={alt}
          width={width}
          height={height}
          className={styles.image}
          sizes="(max-width: 768px) 100vw, 50vw"
        />
      </div>
    </FadeIn>
  ) : null;
}

export default ImageParagraph