/* eslint-disable */
import React, { useEffect } from "react";

export default function TrackFull(props: any) {

  useEffect(() => {
    // @ts-ignore
    (function(s,i,u,o,c,w,d,t,n,x,e,p,a,b){(a=document.getElementById(i.root)).svgatorPlayer={ready:(function(a){b=[];return function(c){return c?(b.push(c),a.svgatorPlayer):b}})(a)};w[o]=w[o]||{};w[o][s]=w[o][s]||[];w[o][s].push(i);e=d.createElementNS(n,t);e.async=true;e.setAttributeNS(x,'href',[u,s,'.','j','s','?','v','=',c].join(''));e.setAttributeNS(null,'src',[u,s,'.','j','s','?','v','=',c].join(''));p=d.getElementsByTagName(t)[0];p.parentNode.insertBefore(e,p);})('91c80d77',{"root":"eHhAyXo4Cwq1","version":"2022-05-04","animations":[{"elements":{"eHhAyXo4Cwq2":{"stroke-dashoffset":[{"t":0,"v":2722.344127},{"t":2000,"v":5444.69}]}},"s":"MDQA1ZDhmMzY3OPDg5ODZFNzU4KOEY3ZDgzODJSEMzY0ZTQ2NDFQ0NDQ0NDAzNTjc4N2Q4Njc5WNzc4ODdkODMV4Mk8zNjRlUzIQ1NDAzNlQ3ZHDg4UTc5ODY3PNVY4ODdkODMS4Mjg3MzY0ZTGQ1NDAzNjdhNC2Q4MDgwMzY0RZVQ0NTQwMzYR3NTgwODg3OTIg2ODI3NTg4NMzkzNjRlN2E3INTgwODc3OTQAwMzY4Nzg0NzFk3OVk3ODM2NPGU0NTQwMzY3FYTg0ODczNjRXlNDU0NDQ0OTDE/"}],"options":"MDOAxMDgyMjk3YNTdiRDY4Nzk3LYjI5NDEyOTcYzWDc2Njg2YjCI5ODQ/"},'https://cdn.svgator.com/ply/','__SVGATOR_PLAYER__','2022-05-04',window,document,'script','http://www.w3.org/2000/svg','http://www.w3.org/1999/xlink')
  }, [])

  return (
    <svg id="eHhAyXo4Cwq1" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1254 524" shapeRendering="geometricPrecision" textRendering="geometricPrecision"><defs><linearGradient id="eHhAyXo4Cwq2-stroke" x1="1159.98" y1="321" x2="196.559" y2="965.217" spreadMethod="pad" gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)"><stop id="eHhAyXo4Cwq2-stroke-0" offset="0%" stopColor="#b8d6ec"/><stop id="eHhAyXo4Cwq2-stroke-1" offset="49.4495%" stopColor="#e0effa"/><stop id="eHhAyXo4Cwq2-stroke-2" offset="100%" stopColor="#fff"/></linearGradient></defs><path id="eHhAyXo4Cwq2" d="M-0.020874,467C147.979,467,139.082,400.132,312.992,430c173.911,29.868,70.008,144,414,26c237.689-81.535,308.988,98,386.988,62c32-14.769,209.01-51,107.01-346C1184.11,65.3604,999.978,1,861.978,1c-106,0-254,82-326,98-90,20-218-98-362-98" fill="none" stroke="url(#eHhAyXo4Cwq2-stroke)" strokeWidth="2" strokeDashoffset="2722.344127" strokeDasharray="2722.344127"/>
    </svg>
  );
}