import { useEffect, useRef, useState } from "react";

import Container from "@mui/material/Container";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

import useOnScreen from "@hooks/useOnScreen";

import { CustomCountdown } from "@components/Common/CustomCountdown";
import Box from "@mui/material/Box";
import { DrupalNode } from "next-drupal/dist/types";

interface CountdownProps {
  fieldData: {
    field_date: string;
    field_title?: string;
    field_description?: {
      value: string,
    };
  };
  node: DrupalNode;
}

export const CountdownParagraph:React.FC<CountdownProps> = ({fieldData}) => {
  const ref: any = useRef(null);
  const onScreen: boolean = useOnScreen(ref);
  const [loaded, setLoadead] = useState(false);
  
  useEffect(()=> {
    if(onScreen) setLoadead(true);
  }, [onScreen])

  return (
    <>
      <Fade ref={ref} in={loaded} appear={false} timeout={1000} style={{transitionDelay: '100ms'}}>
        <Container maxWidth={false}>
          {fieldData?.field_title && <Typography variant="h2" mb={24} textAlign="center">{fieldData?.field_title}</Typography>}
          {fieldData?.field_description && 
            <Typography
              variant="bodyL"
              component="div"
              sx={{textAlign:'center'}}
              mb={48}
              dangerouslySetInnerHTML={{__html: fieldData?.field_description?.value}}
            />
          }

          {typeof fieldData.field_date === 'string' ? 
            <Box pt={64}>
              <CustomCountdown date={fieldData.field_date as string} timeToHide={['seconds']} align="center" />
            </Box> : <></>
          }

        </Container>
      </Fade>
    </>
  )
}

export default CountdownParagraph