import { useEffect, useRef, useState } from "react";
import { DrupalNode} from "next-drupal"

import Row from "@components/Modules/Utils/Row/Row";

import { useTheme } from "@mui/material/styles";

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Navigation} from "swiper";

import ArticleTeaser from "@components/Modules/Article/node--article--teaser";

import style from './CarouselNews.module.scss';
import useOnScreen from "@hooks/useOnScreen";
import { useTranslation } from "next-i18next";


interface CarouselNewsProps {
  articles: DrupalNode[],
}

export default function CarouselNews({articles}: CarouselNewsProps ) {
  const {t} = useTranslation();
  const ref: any = useRef(null);
  const onScreen: boolean = useOnScreen(ref);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [index, setIndex] = useState(null);

  const swiperRef = useRef(null)
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  const items = articles.slice(0, 5)
  const params: object = {
    modules: [Pagination, Navigation],
		slidesPerView:1,
    spaceBetween: 24,
    pagination: true,
    navigation: false,
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 40,
        pagination: false,
        navigation: {
          prevEl: navigationPrevRef.current ? navigationPrevRef.current : undefined,
          nextEl: navigationNextRef.current ? navigationNextRef.current : undefined,
        },
      }
    },
    onBeforeInit: (swiper) => {
      swiperRef.current = swiper;
      setIndex(swiper.activeIndex)
    },
    onSlideChange: (swiper) => { setIndex(swiper.activeIndex) }
	}

  const [loaded, setLoadead] = useState(false);
  useEffect(()=> {
    if(onScreen) setLoadead(true);
  }, [onScreen])
  
  return (
    <Fade ref={ref} in={loaded} appear={false} timeout={1000} style={{transitionDelay: '100ms'}}>
      <Container maxWidth={false} className={style.CarouselNews}>
        <Row my={50} justifyContent="center">

          {!isMobile &&
            <Grid item lg={2} display="flex" alignItems="center">
              {!(index==0) && 
                <Fab color="primary" ref={navigationPrevRef} onClick={() => swiperRef.current?.slidePrev()}>
                  <ArrowBack />
                </Fab>
              }
            </Grid>
          }

          <Grid item xs={12} lg={8}>

            <Swiper {...params} className={style.swiper}>
              { items.map((el, index) => {
                return (
                  <SwiperSlide key={index}>
                    <ArticleTeaser node={el}/>
                  </SwiperSlide>
                )
              })}

            </Swiper>

          </Grid>

          {!isMobile &&
            <Grid item lg={2} display="flex" alignItems="center" justifyContent="flex-end">
              {!(index+2 == items.length) && 
                <Fab color="primary" className={style.slideNext} ref={navigationNextRef} onClick={() => swiperRef.current?.slideNext()}>
                  <ArrowForward />
                </Fab>
              }
            </Grid>
          }

          <Grid item xs={12} display="flex" justifyContent="center">
            <Button
              id='discover-news'
              variant="outlined"
              href={'/next-journal'}
            >{t('news.viewAll')}</Button>
          </Grid>

        </Row>
      </Container>
    </Fade>
  )
}