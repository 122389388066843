import { useTranslation } from "next-i18next";
import { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import * as Icons from '@/src/components/UI/CustomIcons'

import Row from "@components/Modules/Utils/Row/Row";

import FadeIn from "@components/Common/FadeIn/FadeIn";
import { DrupalMediaField, DrupalSimpleTextField, DrupalTextField } from "@model/fields.model";
import { ParagraphModel, ParagraphProps } from "@model/paragraphs.model";
import styles from './ModelloOperativo.module.scss';

interface ModelloOperativoProps {
  field_modello_item: ModelloOperativoItemProps[];
}

interface ModelloOperativoItemProps extends ParagraphModel {
  index: number;
  field_modello_item_icon: DrupalMediaField;
  field_modello_item_title: DrupalSimpleTextField;
  field_modello_item_description: DrupalSimpleTextField;
  field_modello_item_content: DrupalTextField
}

const ModelloOperativo: React.FC<ParagraphProps<ModelloOperativoProps>> = ( { fieldData: { field_modello_item } } ) => {

  if (!Array.isArray(field_modello_item) || field_modello_item?.length <= 0) return <></>;

  return (
    <FadeIn>
      <Box className={styles.modelloOperativo}>
        {field_modello_item.map((item, i) =>  <ModelloOperativoTab key={item.id} {...item} index={i}/> )}
      </Box>
    </FadeIn>
  )
}

const ModelloOperativoTab: React.FC<ModelloOperativoItemProps> = (props) => {
  const {field_modello_item_icon, field_modello_item_title, field_modello_item_description, field_modello_item_content, index} = props;

  const { t } = useTranslation();
  const Icon = field_modello_item_icon ? Icons[field_modello_item_icon?.name?.replace(/\.[^/.]+$/, "")] : null;
  const [open, setOpen] = useState(false)
  const color = ['#1E3591', '#2E5EA6', '#6096DC'];

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className={styles.modelloItem} style={{ backgroundColor: color[index] }}>
        <div className={styles.modelloContent}>
          {Icon && <Icon />}
          <Typography className={styles.title} variant="h4">{field_modello_item_title}</Typography>
          <Typography className={styles.desc} variant="bodyL" dangerouslySetInnerHTML={{ __html: field_modello_item_description }} />
          <Button
            variant="text"
            color={'secondary'}
            size="small"
            className={styles.itemCta}
            startIcon={<AddIcon />}
            onClick={handleDrawerToggle}
          >{t('paragraphs.modelloOperativo.cta')}</Button>
        </div>
      </div>

      <Drawer
        PaperProps={{ className: styles.paperOperativo }}
        open={open}
        ModalProps={{ keepMounted: false }}
        onClose={handleDrawerToggle}
        SlideProps={{  direction: 'up' }}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: '100%',
            backgroundColor: color[index],
            display: { lg: "flex" },
            justifyContent: {lg: "center"},
            alignItems: {lg: "center"},
            py: 32
          }
        }}
      >
        <Box className={styles.box} display="flex" justifyContent="center" alignItems="center">
          <Container maxWidth={false}>
            <Row spacing={0} justifyContent={'center'}>
              <Grid item sm={8}>
                <div className={styles.titleCont}>
                  {Icon && <Icon />}
                  <Typography className={styles.title} variant="h4">{field_modello_item_title}</Typography>
                </div>
                <Typography className={styles.desc} variant="bodyL" dangerouslySetInnerHTML={{ __html: field_modello_item_content?.processed }} />
              </Grid>
            </Row>
          </Container>
        </Box>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerToggle}
          className={styles.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </Drawer>
    </>
  )
}

export default ModelloOperativo;