import { Autoplay, EffectFade, Pagination, SwiperOptions } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './Hero.module.scss';

import { FC } from "react";
import { DrupalDateField, DrupalLinkField, DrupalMediaField, DrupalSimpleTextField, DrupalTextField } from "@model/fields.model";
import { ParagraphModel, ParagraphProps } from '@model/paragraphs.model';
import Slide from './Slide';

interface HeroModel {
	field_hero_style: any;
	field_hero_item: HeroItemModel[];
}

export interface HeroItemModel extends ParagraphModel{
	field_copyright: any;
	field_date: DrupalDateField | string;
	field_description: DrupalTextField;
	field_image: DrupalMediaField;
	field_image_darkmode: DrupalMediaField;
	field_immagine: DrupalMediaField;
	field_immagine_darkmode: DrupalMediaField;
	field_link: DrupalLinkField;
	field_logo_hero: DrupalMediaField;
	field_secondary_link: DrupalLinkField;
	field_title: DrupalSimpleTextField;
}


const Hero: FC<ParagraphProps<HeroModel>> = ({ fieldData, node }) => {
	const {field_hero_style, field_hero_item = []} = fieldData

	const params: SwiperOptions = {
		spaceBetween: 0,
		slidesPerView: 1,
		modules: [Pagination, EffectFade, Autoplay],
		effect: "fade",
		fadeEffect: { crossFade: true },
		virtualTranslate: true,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
		},
		speed: 1000,
		pagination: {
			clickable: true,
			bulletActiveClass: styles.swiperBulletActive,
			renderBullet: (index: number, className: string): string => `<span class="${className} ${styles.swiperBullet}"></span>`
		},
		loop: true,
	}

	return (
		<Swiper {...params} className={`${styles.heroSwiper} ${styles[field_hero_style]}`} >
			{field_hero_item.map((slide: ParagraphModel & HeroItemModel, index: number) => {
				return (
					<SwiperSlide key={slide.id} className={`${styles.swiperItem}`} >
						{() => <Slide slide={slide} index={index} key={slide.id} node={node} />}
					</SwiperSlide>
				)
			})}
		</Swiper>
	)
}

export default Hero