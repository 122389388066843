

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import EastIcon from '@mui/icons-material/East';
import * as Icons from '../../UI/CustomIcons/index';

import Box from '@mui/material/Box';
import { useTranslation } from 'next-i18next';
import styles from './Obbiettivi.module.scss';
import { ObbiettivoProps } from "./Obbiettivi.model";
import { getImageData, getUrlAttributes } from "@helper/media";
import Image from "next/image";

const ObbiettivoContent: React.FC<ObbiettivoProps> = ({data, light}) =>{
  const {
    id,
    field_title : title,
    field_content : content,
    field_link: cta,
  } = data;

  const {t} = useTranslation();

  return (
    <>
      {title && <Typography variant="subtitle" component="div" className={styles.itemTitle} dangerouslySetInnerHTML={{__html: title}} />}
      {content && <Typography variant="bodyL" component="div" fontWeight={'light'} className={styles.itemDesc} marginBottom={32} dangerouslySetInnerHTML={{__html: content.processed}} />}
      {cta?.url && (
        <Button
          id={id}
          variant="text"
          color={light ? 'secondary' : 'primary'}
          size="small"
          className={styles.itemCta}
          {...getUrlAttributes(cta)}
          startIcon={<EastIcon />}
        >
          {cta?.title || t("discoverMore")}
        </Button>
      )}
    </>
  )
}

const ObbiettivoIcon: React.FC<ObbiettivoProps> = ({data, light}) => {
  const {
    field_icon : iconName,
    field_number : number,
  } = data;

  const Icon = iconName ? Icons[iconName?.name?.replace(/\.[^/.]+$/, "")] : null;
  const iconColor = light ? (number ? 'rgba(255, 255, 255, 0.2)' : '#ffffff') : false;

  return (
    <Box className={styles.item} textAlign="center">
      {Icon &&
        <Box className={styles.itemIcon}>
          <Icon color={iconColor} />
          {number && (
            <Typography variant="h1" component="div" className={styles.itemNumber} dangerouslySetInnerHTML={{ __html: number }} />
          )}
        </Box>
      }

      <ObbiettivoContent data={data} light={light}/>
  </Box>
  )
}

const ObbiettivoNumber: React.FC<ObbiettivoProps> = ({data, light}) => {
  const {
    field_number : number,
  } = data;

  return (
    <Box className={styles.item} textAlign="center">
      <Box className={styles.itemIcon}>
        <Icons.Numeri color={light && 'rgba(255,255,255,0.2)'} />
        <Typography variant="h1" component="div" className={styles.itemNumber} dangerouslySetInnerHTML={{__html: number}}/>
      </Box>
      <ObbiettivoContent data={data} light={light}/>
    </Box>
  )
}

const ObbiettivoImage: React.FC<ObbiettivoProps> = ({data, light}) => {

  const {src, alt, width, height} = getImageData(data.field_image);

  return (
    <Box className={styles.item} textAlign="center">
      <Box width={'100%'} className={styles.itemImage}>
        <Image src={src} alt={alt} width={width} height={height} sizes="(max-width: 768px) 50vw, 20vw"/>
      </Box>
      <ObbiettivoContent data={data} light={light}/>
    </Box>
  )
}

const Obbiettivo: React.FC<ObbiettivoProps> = (props: ObbiettivoProps) => {
  
  const type: 'icon' | 'image' = props?.data?.behavior_settings?.style_options?.obbiettivo_item_asset?.better_css_class || 'icon';

  const obiettivoMap = {
    icon: ObbiettivoIcon,
    image: ObbiettivoImage
  }

  const Component = obiettivoMap[type] || null;
  return <Component {...props} />;
}

export default Obbiettivo