
import Container from "@mui/material/Container";

import Row from "@components/Modules/Utils/Row/Row";
import style from './Tab.module.scss';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  isParagraphHolder: boolean;
}

const TabPanel:React.FC<TabPanelProps> = (props) => {
  const { children, value, index, isParagraphHolder, ...other } = props;

  if (isParagraphHolder) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        style={{height: '100%'}}
        {...other}
      >
        {value === index && children}
      </div>
    )
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{height: '100%'}}
      {...other}
    >
      <Container maxWidth={false} className={style.tabContent}>
        <Row className={style.tabContentInner} justifyContent={'center'}>
          {value === index && children}
        </Row>
      </Container>
    </div>
  );
}

export default TabPanel