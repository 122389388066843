import Image from "next/image";
import React, { createRef, useRef } from 'react';

import { getThumbnailAlt, getThumbnailUrl } from '@helper/media';
import useOnScreen from '@hooks/useOnScreen';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '@styles/mui/theme';

import Row from "@components/Modules/Utils/Row/Row";

import { useTranslation } from 'next-i18next';
import styles from './EntryPoint.module.scss';

import { ParagraphModel, ParagraphProps } from "@model/paragraphs.model";
import { DrupalLinkField, DrupalMediaField, DrupalSimpleTextField, DrupalTextField } from "@model/fields.model";

interface EntryPointProps {
  field_entry_point_item: EntryPointItemProps[];
}

interface EntryPointItemProps extends ParagraphModel {
  field_entry_point_item_title: DrupalSimpleTextField;
  field_entry_point_item_content: DrupalTextField;
  field_entry_point_item_image: DrupalMediaField;
  field_entry_point_item_link: DrupalLinkField;
}

const EntryPoint: React.FC<ParagraphProps<EntryPointProps>> = ({ fieldData }) => {

  const { field_entry_point_item } = fieldData

  const {t} = useTranslation();
  const ref: any = useRef(null);
  const onScreen: boolean = useOnScreen(ref);
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down('lg'));

  const boxRef = useRef([]);

  boxRef.current = field_entry_point_item?.map((_, i) => boxRef.current[i] ?? createRef());
  const onMouseEnterHandler = (index) => {
    if (!isMobile) boxRef.current[index].current.classList.add('hover');
  }
  const onMouseLeaveHandler = (index) => {
    if (!isMobile) boxRef.current[index].current.classList.remove('hover');
  }
  const onBtnPlus = (index) => {
    boxRef.current[index].current.classList.add('hover');
  }
  const onBtnMinus = (index) => {
    boxRef.current[index].current.classList.remove('hover');
  }

  return (
    <Fade ref={ref} in={onScreen} appear={onScreen} timeout={1000} style={{ transitionDelay: '100ms' }}>
      <Container className={styles.entryPointContainer} maxWidth={false}>
        <Row className={styles.entryPointRow} >

          {field_entry_point_item && field_entry_point_item?.map((el: EntryPointItemProps, i: number): JSX.Element => {
            return (
              <React.Fragment key={el?.id}>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  ref={boxRef.current[i]}
                  className={styles.contentMain}
                  onMouseEnter={() => onMouseEnterHandler(i)}
                  onMouseLeave={() => onMouseLeaveHandler(i)} >
                  {el?.field_entry_point_item_image ?
                    <div className={styles.contentImage}>
                      <Image
                        src={getThumbnailUrl(el?.field_entry_point_item_image) ?? ''}
                        alt={getThumbnailAlt(el?.field_entry_point_item_image) ?? ''}
                        fill
                        sizes="(max-width: 768px) 100vw, 50vw"
                        style={{
                          objectFit: "cover"
                        }}
                      />
                    </div> 
                    : <></>
                  }

                  <div className={styles.contentImageGradient}></div>
                  {el?.field_entry_point_item_title ?
                    <Row>
                      <Grid item xs={false} lg={1} />
                      <Grid className={styles.content} item xs={12} lg={10}>
                        <span className={styles.btnPlus} onClick={() => onBtnPlus(i)}>+</span>
                        <span className={styles.btnMinus} onClick={() => onBtnMinus(i)}>-</span>
                        <div className={styles.titleCont}>
                          <Typography className={styles.title} variant="h3" component="h3">{el.field_entry_point_item_title}</Typography>
                        </div>
                        <div className={styles.entryText}>
                          {el.field_entry_point_item_content && <Typography className={styles.desc} variant="bodyL" component="div" dangerouslySetInnerHTML={{ __html: el.field_entry_point_item_content.processed }} />}
                          {el.field_entry_point_item_link && 
                            <Button
                              id={el.id}
                              variant={'transparent'}
                              href={el.field_entry_point_item_link.url}
                            >{t("discoverMore")}</Button>
                          }
                        </div>
                      </Grid>
                    </Row>
                    : <></>
                  }

                  <div className={styles.tendinaCont} />

                </Grid>
              </React.Fragment>
            )
          })}
        </Row>
      </Container>
    </Fade>
  )
}

export default EntryPoint