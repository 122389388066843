import React from "react";

export default function OndeTop() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 1400 869"
    >
      <path
        fill="url(#paint0_linear_787_23518)"
        fillOpacity="0.5"
        d="M165.377 40.482c-72.384-2-140.422 103.44-165.393 156.41v671.113H1399.98V140.924s-70.04-29.983-171.71-29.983c-221.34 0-401.809 132.924-577.417 135.922C475.245 249.861 255.857 42.98 165.377 40.482z"
      ></path>
      <path
        fill="url(#paint1_linear_787_23518)"
        fillOpacity="0.8"
        d="M190.672 71.464c-90.084 54.643-165.717 67.128-190.688 69.46v727.081H1399.98V119.936c-44.59-34.314-153.61-101.942-233-101.942-135.72 0-256.85 122.93-336.627 122.93-294.788 0-374.08-140.92-476.72-140.92-66.157 0-111.884 40.478-162.961 71.46z"
      ></path>
      <path
        stroke="#B8D6EC"
        d="M-.016 178.902c37.943-29.317 92.912-90.448 177.068-82.453 80.892 7.686 379.43 121.93 519.527 113.935 140.098-7.996 417.371-69.46 531.691-69.46 91.45 0 152.58 20.988 171.71 31.482"
      ></path>
      <path
        stroke="#E0EFFA"
        d="M-.016 164.41c38.105-3.998 133.385-25.785 209.66-80.953 95.344-68.96 194.093-60.465 230.577-52.47 74.913 16.417 297.22 144.917 412.995 133.423C968.991 152.917 1113.47 20.993 1222.92 25.99c87.56 3.998 154.53 39.977 177.06 57.467"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_787_23518"
          x1="699.984"
          x2="699.984"
          y1="40.454"
          y2="377.505"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0EFFA"></stop>
          <stop offset="1" stopColor="#fff"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_787_23518"
          x1="699.984"
          x2="699.984"
          y1="0.005"
          y2="497.005"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0EFFA"></stop>
          <stop offset="1" stopColor="#fff"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}