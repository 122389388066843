import {useCallback} from 'react';
import { Theme } from '@mui/material';

export const useStyles = (completed?: boolean) => {
  const timeBlock = useCallback(
    (theme: Theme) => ({
      padding: 8,
      minWidth: 72,
      height: 72,
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: completed ? 'grey.500' : 'white',
      'span': {
        color: completed ? 'white !important' : null
      },
      [theme.breakpoints.up('xl')]: {
        minWidth: 80,
        height: 80,
      }
    }),
    [completed],
  );

  const timeBlockNum = useCallback(() => ({
    fontSize: '2.25rem !important',
    lineHeight: '2.75rem !important',
  }), []);

  return {timeBlock, timeBlockNum};
};
