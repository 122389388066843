import { useEffect, useMemo, useRef, useState } from "react";
import { useRouter } from "next/router";
import Image from 'next/image';

import {getThumbnailAlt, getThumbnailUrl} from "@helper/media";
import useOnScreen from "@hooks/useOnScreen";

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Row from "@components/Modules/Utils/Row/Row";

import styles from './Pillar.module.scss'

export default function Pillar({fieldData: {field_pillar_item, field_pillar_cta}}) {
  const ref: any = useRef(null);
  const onScreen: boolean = useOnScreen(ref);
  const [loaded, setLoadead] = useState(false);

  const router = useRouter();
  const isRenEnerComCase = useMemo(() => router?.asPath.includes('/eventi/e-mobility/'), [router]);

  useEffect(()=> {
    if(onScreen) setLoadead(true);
  }, [onScreen])

  return (
    <Fade
      ref={ref}
      in={loaded}
      appear={false}
      timeout={1000}
      style={{ transitionDelay: "100ms" }}
    >
      <Container
        maxWidth={false}
        className={`${styles.pillar} ${
          isRenEnerComCase ? styles["comunita-energetiche-rinnovabili"] : ""
        }`}
      >
        
        {field_pillar_item?.length && (
          <Row justifyContent='center'>
            <Grid item lg={12}>
              {field_pillar_item.map((item, index) => <PillarItem key={index} item={item} reverse={index % 2 == 0 ? false : true}/>)}
            </Grid>
          </Row>
        )}

        {field_pillar_cta?.length > 0 && (
          <Row justifyContent="center" rowSpacing={40} mt={50}>
            {field_pillar_cta.map(
              (cta, index) =>
                cta.field_pillar_cta_button && (
                  <Grid key={index} item xs={12} lg={4}>
                    <Button
                      id={cta.id}
                      href={cta.field_pillar_cta_button.url}
                      variant="outlined"
                      fullWidth
                    >
                      {cta.field_pillar_cta_button.title}
                    </Button>
                  </Grid>
                )
            )}
          </Row>
        )}
      </Container>
    </Fade>
  );
}

function PillarItem({item: {field_pillar_item_image, field_pillar_item_title, field_pillar_item_content}, reverse}) {

    const image = getThumbnailUrl(field_pillar_item_image);
    const imageAlt = getThumbnailAlt(field_pillar_item_image);
    const title = field_pillar_item_title || null;
    const content = field_pillar_item_content?.processed;
    return (
      <Row className={`${styles.pillarItem} ${reverse && styles.reverse}`}>

        <Grid item xs={12} lg={3}>
          <div className={styles.imageWrapper}>
            { image && <Image src={image} alt={imageAlt} width={440} height={360} quality={100} className={styles.image}/> }
          </div>
        </Grid>

        <Grid item xs={10} lg={4} className={styles.content}>
          {title && <Typography variant="h3" mb={24}>{title}</Typography>}
          {content && <Typography variant="bodyL" dangerouslySetInnerHTML={{__html: content}}/>}
        </Grid>
      </Row>
    )
}