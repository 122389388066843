import Image from 'next/image';
import { NextRouter, useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import { getThumbnailUrl, getVideoUrl } from '@helper/media';
import useWindowSize, { WindowSize } from '@hooks/useWindowSize';

import Play from '@components/UI/CustomIcons/Play';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import FadeIn from '@components/Common/FadeIn/FadeIn';
import { DrupalMediaField, DrupalSimpleTextField } from '@model/fields.model';
import { ParagraphProps } from '@model/paragraphs.model';

import styles from './Video.module.scss';

interface FieldData {
  field_video: DrupalMediaField;
  field_video_preview: DrupalMediaField;
  field_video_title?: DrupalSimpleTextField;
  field_video_subtitle?: DrupalSimpleTextField;
}

export const Video: React.FC<ParagraphProps<FieldData>> = ({ fieldData }) => {
  const { field_video_title, field_video, field_video_preview } = fieldData;

  const router: NextRouter = useRouter();
  const size: WindowSize = useWindowSize();
  const [showCover, setShowCover] = useState(field_video_preview != undefined);
  const refImg: any = useRef(null);
  const refVideo: any = useRef(null);
  const refContainer: any = useRef(null);

  useEffect(() => {
    setShowCover(field_video_preview != undefined)
  }, [router.asPath])

  useEffect(() => {
    if (!refContainer.current) return;
    refContainer.current.style.minHeight = refVideo.current ? refVideo.current.clientHeight + 'px' : refImg.current.clientHeight + 'px';
  }, [size])

  return (
    <FadeIn>
      <Container ref={refContainer} disableGutters maxWidth={false} className={styles.videoPlayer}>
        {((showCover && field_video_preview) ? (
          <Box ref={refImg}>
            <Image
              className={styles.preview}
              alt={field_video_title ? field_video_title : 'Video placeholder'}
              src={getThumbnailUrl(field_video_preview)}
              width={field_video_preview?.field_media_image?.resourceIdObjMeta?.width}
              height={field_video_preview?.field_media_image?.resourceIdObjMeta?.height}
            />
            <IconButton className={styles.btnPlay} onClick={() => setShowCover(false)}>
              <Play />
            </IconButton>
          </Box>
          ) : (
          <Box>
            <video ref={refVideo}
              className={styles.video}
              autoPlay={!!field_video_preview}
              controls
              controlsList="nodownload"
              playsInline
            >
              <source src={getVideoUrl(field_video)} type={field_video?.field_media_video_file?.filemime || "video/mp4"} />
            </video>
          </Box>
          )
        )}
      </Container>
    </FadeIn>
  )
}

export default Video