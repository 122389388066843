import { SvgIcon, SvgIconProps } from "@mui/material";

const IconDistrictHeating = ({ stroke, ...otherProps }: { stroke: string; } & SvgIconProps) => {
  
  return (
    <SvgIcon
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <defs>
        <clipPath id="clip0_503_33134">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>

      <g fill="none" clipPath="url(#clip0_503_33134)">
        <path
          d="M0.714355 17.8574V29.286"
          stroke={stroke || "#4F9E30"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.52393 17.8574V29.286"
          stroke={stroke || "#4F9E30"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.714355 29.2852H4.52388"
          stroke={stroke || "#4F9E30"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.714355 17.8574H4.52388"
          stroke={stroke || "#4F9E30"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.2856 17.8574V29.286"
          stroke={stroke || "#4F9E30"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.4761 29.2852H29.2856"
          stroke={stroke || "#4F9E30"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.4761 17.8574V29.286"
          stroke={stroke || "#4F9E30"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.2856 17.8574H25.4761"
          stroke={stroke || "#4F9E30"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.52393 27.3809H25.4763"
          stroke={stroke || "#4F9E30"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.52393 19.7617H12.143"
          stroke={stroke || "#4F9E30"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.4762 19.7617H17.8572"
          stroke={stroke || "#4F9E30"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.1428 17.8574V19.7622"
          stroke={stroke || "#4F9E30"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.8572 17.8574V19.7622"
          stroke={stroke || "#4F9E30"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.1428 17.8574H17.8571"
          stroke={stroke || "#4F9E30"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.9999 14.0482C18.6818 14.0482 21.6666 11.0634 21.6666 7.38151C21.6666 3.69961 18.6818 0.714844 14.9999 0.714844C11.318 0.714844 8.33325 3.69961 8.33325 7.38151C8.33325 11.0634 11.318 14.0482 14.9999 14.0482Z"
          stroke={stroke || "#4F9E30"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 7.38147L17.694 4.6875"
          stroke={stroke || "#4F9E30"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.0952 17.8572V13.7715"
          stroke={stroke || "#4F9E30"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.9048 17.8572V13.7715"
          stroke={stroke || "#4F9E30"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
};


export default IconDistrictHeating;