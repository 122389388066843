import {useEffect, useRef, useState} from 'react';
import {getThumbnailUrl} from "@helper/media";

import {useTheme} from '@mui/material/styles';
import Container from '@mui/material/Container'
import Fade from '@mui/material/Fade'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import useMediaQuery from "@mui/material/useMediaQuery";

import Row from "@components/Modules/Utils/Row/Row";

import Numeri from '@components/UI/CustomIcons/Numeri';
import Next from '@components/UI/AnimatedSvg/Next';

import useOnScreen from "@hooks/useOnScreen";
import styles from './Approccio.module.scss';

interface FieldData {
  fieldData: any;
  field_approccio_background?: string;
  field_approccio_item?: any
}

interface BoxApproccioProps {
  item: any;
  index: number;
}

export default function Approccio({ fieldData: { field_approccio_background, field_approccio_item } }: FieldData) {

  const ref: any = useRef(null);
  const onScreen: boolean = useOnScreen(ref);

  const backGroundImageUrl = getThumbnailUrl(field_approccio_background);
  const refContainer = useRef(null);
  const [y, setY] = useState(0);

  const [loaded, setLoadead] = useState(false);
  useEffect(()=> {
    if(onScreen) setLoadead(true);
  }, [onScreen])

  useEffect(() => {
    addEventListener('scroll', (event) => {
      setY(window.scrollY);
    });
  }, []);

  useEffect(() => {
    const header = document.getElementsByTagName('header')[0];
    if (!refContainer.current) return; 
    refContainer.current.style.minHeight = backGroundImageUrl ? `calc(80vh - ${header.clientHeight}px )` : 'auto';
  }, [y]);


  return (
    <Fade ref={ref} in={loaded} appear={false} timeout={1000} style={{transitionDelay: '100ms'}}>
      <Container maxWidth={false} className={styles.approccioWrapper} ref={refContainer} >
        <Row spacing={20} className={styles.row}>
          {field_approccio_item?.map((item, index) => <BoxApproccio key={index} index={index} item={item} />)}
        </Row >

        <div className={styles.animatedNext}>
          <Next />
        </div>
      </Container >
    </Fade>
  )
}


function BoxApproccio(props: BoxApproccioProps) {
  const { item, index } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  function order (index) {
    switch(index) {
      case 0:
        return 1;
      case 1:
        return 4;
      case 2:
        return 2;
      case 3:
        return 3;
    }
  };

  const orderMobile = isMobile ? order(index) : index;

  return (
    <>
      {(!isMobile && index % 2 != 0) && <Grid key={index + 'empty'} item xs={false} lg={2} order={index}/>}
      <Grid key={index} className={styles.content} item lg={5} order={orderMobile}>
        <Row spacing={20} justifyContent={'center'}>
          <Grid className={styles.item} item lg={6} display="flex" flexDirection="column" alignItems="center">
            <div className={styles.itemIcon}>
              <Numeri color="#ffffff"/>
              {item.field_approccio_item_title && <Typography variant="h1" component="h3" className={styles.title} >{item.field_approccio_item_title}</Typography>}
            </div>
            <Typography variant="h5" component="h5" className={styles.subTitle}>{item.field_approccio_item_subtitle}</Typography>
            <Typography variant="bodyL" component="p" className={styles.desc}>{item.field_approccio_item_content.processed}</Typography>
          </Grid>
        </Row >
      </Grid>
    </>
  )
}
