

/* eslint-disable */
import React, { useEffect } from "react";

export default function Progress(props: any) {

  useEffect(() => {
    // @ts-ignore
    (function(s,i,u,o,c,w,d,t,n,x,e,p,a,b){(a=document.getElementById(i.root)).svgatorPlayer={ready:(function(a){b=[];return function(c){return c?(b.push(c),a.svgatorPlayer):b}})(a)};w[o]=w[o]||{};w[o][s]=w[o][s]||[];w[o][s].push(i);e=d.createElementNS(n,t);e.async=true;e.setAttributeNS(x,'href',[u,s,'.','j','s','?','v','=',c].join(''));e.setAttributeNS(null,'src',[u,s,'.','j','s','?','v','=',c].join(''));p=d.getElementsByTagName(t)[0];p.parentNode.insertBefore(e,p);})('91c80d77',{"root":"eUp6awOeDE11","version":"2022-05-04","animations":[{"elements":{"eUp6awOeDE113":{"transform":{"data":{"o":{"x":416,"y":4,"type":"corner"},"t":{"x":-416,"y":-4}},"keys":{"s":[{"t":1200,"v":{"x":0,"y":1}},{"t":1500,"v":{"x":0.499999,"y":1}},{"t":1800,"v":{"x":0.999999,"y":1}}]}}},"eUp6awOeDE114":{"transform":{"data":{"o":{"x":208,"y":4,"type":"corner"},"t":{"x":-208,"y":-4}},"keys":{"s":[{"t":600,"v":{"x":0,"y":1}},{"t":900,"v":{"x":0.5,"y":1}},{"t":1200,"v":{"x":1,"y":1}}]}}},"eUp6awOeDE115":{"transform":{"data":{"o":{"x":0,"y":4,"type":"corner"},"t":{"x":0,"y":-4}},"keys":{"s":[{"t":0,"v":{"x":0,"y":1}},{"t":300,"v":{"x":0.18,"y":1}},{"t":600,"v":{"x":0.36,"y":1}}]}}}},"s":"MDIA1ZmM1NmNhZXWJmYmNhYmJlGYjNiOWI4NmME4NDdiODI3YTXdhNzY2Y2FlYKjNiY2FmYWRiTZWIzYjliODZXjODQ3Yjc2NmPNQYjNiZWFmYSmNhYkFiZWIzDYjliOGJkNmMG4NDdiNzY2Y2UIwYjNiNmI2NKmM4NDdiNzY2LY2FiRWI2YmVKhZmJjYjhhYmAJlYWZCNmM4NVGIwYWJiNmJkDYWY3NjZjYmRVTYmFHYWZhZmBFlNmM4NFM3YYTc4ODA3NlY2RY2IwYmFiZDZGjODQ3YjdhN2CFjNw|"}],"options":"MDHAxMDgyMjlHNV2E3YjY4Nzk3EYjI5TTQxMjkE3Mzc2Njg2YjXI5ODQ/"},'https://cdn.svgator.com/ply/','__SVGATOR_PLAYER__','2022-05-04',window,document,'script','http://www.w3.org/2000/svg','http://www.w3.org/1999/xlink')
  }, [])

  return (
    <svg id="eUp6awOeDE11" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 600 8" shapeRendering="geometricPrecision" textRendering="geometricPrecision"><g><g><g><path d="M212,0h176c2.2,0,4,1.8,4,4v0c0,2.2-1.8,4-4,4h-176c-2.2,0-4-1.8-4-4v0c0-2.2,1.8-4,4-4Z" fill="#e6e6e6"/></g></g><g><g><path d="M4,0h176c2.2,0,4,1.8,4,4v0c0,2.2-1.8,4-4,4L4,8C1.8,8,0,6.2,0,4v0C0,1.8,1.8,0,4,0Z" fill="#e6e6e6"/></g></g><g><g><path d="M420,0h176c2.2,0,4,1.8,4,4v0c0,2.2-1.8,4-4,4h-176c-2.2,0-4-1.8-4-4v0c0-2.2,1.8-4,4-4Z" fill="#e6e6e6"/></g></g></g><g><path id="eUp6awOeDE113" d="M420,0h176c2.2,0,4,1.8,4,4v0c0,2.2-1.8,4-4,4h-176c-2.2,0-4-1.8-4-4v0c0-2.2,1.8-4,4-4Z" transform="matrix(0 0 0 1 416 0)" fill="#2e5ea6"/><path id="eUp6awOeDE114" d="M212,0h176c2.2,0,4,1.8,4,4v0c0,2.2-1.8,4-4,4h-176c-2.2,0-4-1.8-4-4v0c0-2.2,1.8-4,4-4Z" transform="matrix(0 0 0 1 208 0)" fill="#2e5ea6"/><path id="eUp6awOeDE115" d="M11.111111,0L500,0c6.111111,0,11.111111,1.8,11.111111,4v0c0,2.2-5,4-11.111111,4L11.111111,8C5,8,0,6.2,0,4v0C0,1.8,5,0,11.111111,0Z" transform="matrix(0 0 0 1 0 0)" fill="#2e5ea6"/></g></svg>
  );
}
