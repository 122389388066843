import Link from "next/link";

import Stack from "@mui/material/Stack";

import LinkedIn from '@mui/icons-material/LinkedIn';
import Twitter from '@mui/icons-material/Twitter';
import WhatsApp from '@mui/icons-material/WhatsApp';
import Facebook from '@mui/icons-material/Facebook';

import { frontendUrl } from "@lib/utils";
import { AlternateEmail } from "@mui/icons-material";

export default function SocialShare({node}) {

  const url = frontendUrl(node.path.alias)

  const socials = [
    {
      type: 'LinkedIn',
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
      icon: <LinkedIn color="inherit" fontSize="inherit"  />
    },
    {
      type: 'Twitter',
      url: `https://twitter.com/intent/tweet?original_referer=${url}&text=${node.title}&url=${url}`,
      icon: <Twitter color="inherit" fontSize="inherit"  />
    },
    {
      type: 'WhatsApp',
      url: `whatsapp://send?text=${url}`,
      attributes: {
        "data-action": "share/whatsapp/share",
      },
      icon: <WhatsApp color="inherit" fontSize="inherit"  />
    },
    {
      type: 'Facebook',
      url: `https://www.facebook.com/sharer/sharer.php?u=${url}&title=${node.title}`,
      icon: <Facebook color="inherit" fontSize="inherit" />
    },
    {
      type: 'Email',
      url: `mailto:?subject="${node.title}"&body=${url}`,
      icon: <AlternateEmail color="inherit" fontSize="inherit" />,
    },
  ]
  
  return (
    <>
      <Stack className="social-share" sx={{'& a': {color: "#326623", fontSize: '30px'}}} direction="row" spacing={24}>
        {socials && socials.map((el, i) => {
          return(
            <Link href={el.url} passHref key={'social_'+i} {...el.attributes} target="_blank">
              {el.icon}
            </Link>
          )
        })}
      </Stack>
      
    </>
  )
}