import { useRef } from "react";

import useOnScreen from "@hooks/useOnScreen";
import { getThumbnailUrl } from "@helper/media";

import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Row from "@components/Modules/Utils/Row/Row";

import FadeIn from "@components/Common/FadeIn/FadeIn";
import { ParagraphProps } from "@model/paragraphs.model";
import * as AnimatedSvg from '../../UI/AnimatedSvg';
import styles from './Intro.module.scss';
import { AlignProps, IntroProps } from "./Intro.type";

const Intro: React.FC<ParagraphProps<IntroProps>> = ({ fieldData }) => {

  const { id, field_intro_title, field_intro_background_image, field_intro_icon, field_intro_content, field_intro_button, behavior_settings } = fieldData;

  const ref: any = useRef(null);
  const onScreen: boolean = useOnScreen(ref);
  
  const layout: AlignProps = behavior_settings?.style_options?.intro_layout?.better_css_class || 'center'
  const alignContent = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  }
  const backGroundImageUrl = getThumbnailUrl(field_intro_background_image);

  const AnimationComponent = field_intro_icon ? AnimatedSvg[field_intro_icon?.name?.replace(/\.[^/.]+$/, "")] : null;

  return (
    <FadeIn>
      <Container
        ref={ref}
        maxWidth={false}
        className={`paragraph_intro ${styles.introWrapper} ${(backGroundImageUrl || field_intro_icon) && styles.background}`}
        style={backGroundImageUrl && {
          backgroundImage: `url(${!field_intro_icon && backGroundImageUrl} )`
        }}
      >
        {field_intro_icon && (
          <Box className={`${styles.animatedIcon} ${styles[layout]}`}>
            {onScreen && <AnimationComponent />}
          </Box>
        )}

        <Row spacing={20} justifyContent={alignContent[layout]} className={styles.contentWrapper}>
          {layout == 'left' && <Grid item lg={1} />}
          <Grid className={styles.content} item lg={8} sx={{textAlign: layout}}>
            {field_intro_title && <Typography variant="h2" component="h2" className={styles.title} textAlign={layout}>{field_intro_title}</Typography>}
            {field_intro_content && <Typography variant="bodyL" component="p" className={styles.desc} textAlign={layout} dangerouslySetInnerHTML={{ __html: field_intro_content?.processed }} />}
            {field_intro_button?.url && (
              <Button
                id={id}
                variant="outlined"
                href={field_intro_button?.url}
                className={styles.button}
              >{field_intro_button?.title}</Button>
            )}
          </Grid>
          {layout == 'right' && <Grid item lg={1} />}
        </Row>
      </Container>
    </FadeIn>
  )
}

export default Intro