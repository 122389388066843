import { useState } from "react";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { useTranslation } from "next-i18next";

import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FileUploadIcon from '@mui/icons-material/FileUpload';

export const fileSizeValidator = ({ maxSize }) => {
  
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return (
        parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    );
  }

  return (value) => {
    if (value && value.inputFiles[0] && value.inputFiles[0].size > maxSize) {
      /**
       * Human readable message should be generated!
       */
      // TODO: tradurre in italiano
      return `File is too large, maximum allowed size is ${formatBytes(maxSize)}. Current file has ${formatBytes(value.inputFiles[0].size)}`;
    }
  };
};

export const FileUpload = (props) => {
  const {t} = useTranslation();
  const { input, meta, label, isRequired } = useFieldApi(props);

  const [value, setValue] = useState(input.value ? input.value.name : '');

  const handleChange = (event) => {
    setValue(event.target.value);
    input.onChange(event);
  };

  return (
    <Grid item xs={12} lg={12}>
      <FormControl fullWidth variant="standard" margin="normal">
        <InputLabel htmlFor={input.name} shrink={true} focused={true} filled={true}>{label}</InputLabel>
        <Input
          id={input.name}
          name={input.name}
          type={input.type}
          onChange={handleChange}
          placeholder={t('contactForm.common.upload')}
          value={value}
          error={Boolean(meta.error)}
          required={isRequired}
          endAdornment={
            <InputAdornment position="end">
              <IconButton edge="end" component="label" htmlFor={input.name} >
                <FileUploadIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        {meta.error && <FormHelperText error={Boolean(meta.error)}>{meta.error}</FormHelperText>}
      </FormControl>
    </Grid>
  );
};

export default FileUpload;