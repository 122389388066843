import Image from "next/legacy/image";
import { useEffect, useRef, useState } from 'react';

import { getThumbnailAlt, getThumbnailUrl } from "@helper/media";
import useOnScreen from "@hooks/useOnScreen";

import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Row from "@components/Modules/Utils/Row/Row";

import { useTranslation } from 'next-i18next';
import styles from './CaseHistory.module.scss';

interface FieldData {
  fieldData: any;
}
interface BoxCaseHistoryProps {
  item: any;
  index: number;
}

export default function CaseHistory({ fieldData }: FieldData) {
  const {t} = useTranslation();
  const [types, setTypes] = useState('');
  const chipType1 = useRef(null);
  const chipType2 = useRef(null);


  const filterClick = (e, type) => {
    let typeFilter = "";

    if (type === 'company') {
      chipType1.current.classList.toggle("activeFilter")
      if (chipType2.current.classList.contains("activeFilter")) {
        typeFilter = "pa";
      } else {
        typeFilter = "company";
      }
    }

    if (type === 'pa') {
      chipType2.current.classList.toggle("activeFilter")
      if (chipType1.current.classList.contains("activeFilter")) {
        typeFilter = "company";
      } else {
        typeFilter = "pa";
      }
    }

    if ((chipType1.current.classList.contains("activeFilter") && chipType2.current.classList.contains("activeFilter")) || (!chipType2.current.classList.contains("activeFilter") && !chipType1.current.classList.contains("activeFilter"))) {
      typeFilter = "all";
    }
    setTypes(typeFilter);
    let arrItems = fieldData.field_case_history_item;
    arrItems = arrItems.filter(function (item, index) {
      return typeFilter == item.field_case_history_type || item.field_case_history_type === null || typeFilter == "all";
    });
    setLoadMoreOn(arrItems.length > 3);
    setNext(3);
  }

  const [loadMoreOn, setLoadMoreOn] = useState(fieldData.field_case_history_item?.length > 3 && fieldData.field_case_history_filter ? true : false);
  const imagePerCol = fieldData.field_case_history_filter ? 3 : fieldData.field_case_history_item?.length;
  const [next, setNext] = useState(imagePerCol);
  const handleMoreImage = () => {
    setNext(fieldData.field_case_history_item?.length);
    setLoadMoreOn(false);
  };

  return (
    <>
      {
        fieldData.field_case_history_filter && (
          <>
            <Container maxWidth={false} className={styles.caseHistoryFilter}>
              <Row spacing={0} justifyContent={'center'}>
                <Grid item sm={12}>
                  <div className={styles.filterCont}>
                    <span className={styles.filterTitle}>{t('news.filters.filterFor')}</span>
                    <div className={styles.filterChips}>
                      <Chip className={styles.fliterChip} onClick={(e) => filterClick(e, 'company')} ref={chipType1} label={t("companies")} size="small" />
                      <Chip className={styles.fliterChip} onClick={(e) => filterClick(e, 'pa')} ref={chipType2} label={t("publicAdministrations")} size="small" />
                    </div>
                  </div>
                </Grid>
              </Row>
            </Container>
          </>
        )
      }

      <div className={styles.containItems}>
        {fieldData.field_case_history_item && fieldData.field_case_history_item.slice(0, next)?.map((item, index: number) => {
          {
            if (types === '' || types === 'all' || item.field_case_history_type === null) {
              return (
                <BoxCaseHistory key={index} index={index} item={item} />
              )
            }
            if (types === item.field_case_history_type || item.field_case_history_type === null) {
              return (
                < BoxCaseHistory key={index} index={index} item={item} />
              )
            }
          }
        })
        }
      </div>
      {loadMoreOn &&
        <div className={styles.loadMore}>
          <Button className={styles.loadMoreBtn}
            onClick={handleMoreImage}
            variant="text"
          >{t("news.loadMore")}</Button>
        </div>
      }
    </>
  )
}

function BoxCaseHistory(props: BoxCaseHistoryProps) {
  const {t} = useTranslation();
  const ref: any = useRef(null);
  const onScreen: boolean = useOnScreen(ref);

  const [loaded, setLoadead] = useState(false);
  
  useEffect(()=> {
    if(onScreen) setLoadead(true);
  }, [onScreen])

  const imageUrl = (image) => {
    return getThumbnailUrl(image)
  }
  const { item } = props;
  const refContainer = useRef(null);
  let classNameBg = item.field_case_history_background;
  let classNameAlign = 'align_' + item.field_case_history_text;
  let classNamePosition = 'img_' + item.field_case_history_image_opt;
  
  return (
    <Fade ref={ref} in={loaded} appear={false} timeout={1000} style={{transitionDelay: '100ms'}}>
      <Container data-type={item.field_case_history_type} maxWidth={false} className={`${styles.caseHistoryWrapper} ${styles[classNameBg]}`}>
        <Row spacing={0} justifyContent={'center'}>
          <Grid className={`${styles.content} ${styles[classNameBg]} ${styles[classNamePosition]}`} item sm={12}>
            <div ref={refContainer} className={`${styles.boxCont} ${styles[classNameAlign]}`} >
              {item.field_case_history_subtitle && <Typography variant="overline" component="p" className={styles.overline}>{item.field_case_history_subtitle.processed}</Typography>}
              {item.field_case_history_title && <Typography variant="h4" component="h3" className={styles.title}>{item.field_case_history_title.processed}</Typography>}
              {item.field_case_history_content && <Typography variant="bodyL" component="div" className={styles.desc} dangerouslySetInnerHTML={{ __html: item.field_case_history_content.processed }} />}

             
              {item.field_case_history_url && 
                <Button
                  id={item.id}
                  variant={'outlined'}
                  href={item.field_case_history_url.url}
                >{t("discoverMore")}</Button>
              }
            </div>
           
            {item?.field_case_history_image && 
              <div className={`${styles.boxCont} ${styles.boxImg}`}>
                <div className={styles.image}>
               
                    
                  <Image 
                    src={getThumbnailUrl(item.field_case_history_image)}
                    alt={getThumbnailAlt(item.field_case_history_image)}
                    layout='fill'
                    objectFit="cover"
                  />

                {item?.field_case_history_copyright?.value && <Typography
                      className={styles.copyright}
                      dangerouslySetInnerHTML={{ __html: item?.field_case_history_copyright?.value }}
                      style={{ display: "block" }}
                      variant="bodyL"
                      component="div"
                    />    }

                </div>
                
              </div>
            }

         

          </Grid>
        </Row>
      </Container>
    </Fade>
  )
}
