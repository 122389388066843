import { useEffect, useRef, useState } from "react";

import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Fade from '@mui/material/Fade'
import Grid from '@mui/material/Grid'

import Row from "@components/Modules/Utils/Row/Row";
import useOnScreen from "@hooks/useOnScreen";
import Typography from "@mui/material/Typography";
import styles from './Cta.module.scss';


interface FieldData {
  fieldData: any;
  id: string;
  field_cta_item?: any;
  field_cta_title?: any;
}

export default function Cta({ fieldData: { field_cta_item, field_cta_title } }: FieldData) {

  const ref: any = useRef(null);
  const onScreen: boolean = useOnScreen(ref);
  const [loaded, setLoadead] = useState(false);
  const styleCtaContainer = {
    marginTop: field_cta_title ? "4rem" : '0',
    marginBottom: field_cta_title ? "6rem" : '0',
  }
  const idRegistratiCta = field_cta_title ? "registratiCta" : ''

  useEffect(() => {
    if (onScreen) setLoadead(true);
  }, [onScreen])

  return (
    <Fade ref={ref} in={loaded} appear={false} timeout={1000} style={{ transitionDelay: '100ms' }}>
      <Container id={idRegistratiCta} style={styleCtaContainer} className={styles.ctaContainer} maxWidth={false} >
        {field_cta_item.length > 0 && (
          <Row justifyContent='center' rowSpacing={16} py={16} >
            {field_cta_title && <Typography className={styles.ctaTitle} variant="h2" component="h2" dangerouslySetInnerHTML={{ __html: field_cta_title?.processed }} />}
            {field_cta_item.map((cta, index) => (
              cta.field_cta_item_link &&
              <Grid key={index} item xs={12} lg={4}>

                {/* TODO: Sostituire Button con Button MUI, fix da drupal */}
                <Button
                  id={cta.id}
                  href={cta.field_cta_item_link.url}
                  variant={cta.field_cta_item_color === 'primary' ? 'contained' : 'outlined'}
                  referrerPolicy={cta.field_referrer ? "unsafe-url" : "origin"}
                  fullWidth
                >{cta.field_cta_item_link.title}</Button>
              </Grid>
            ))}
          </Row>
        )}
      </Container>
    </Fade>
  )
}