import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import Countdown from "react-countdown";
import CustomCountdownBlock from "./CustomCountdownBlock";
import { ICustomCountdown, TimeTypes } from "./types";

const TIME_TYPES: Array<TimeTypes> = ["days", "hours", "minutes", "seconds"];

const CustomCountdown: React.FC<ICustomCountdown> = ({ date, timeToHide, align }) => {
  const { t } = useTranslation();

  const existsTimeToHide = timeToHide && Array.isArray(timeToHide);

  const timeToShows: Array<TimeTypes> = useMemo(
    () =>
      existsTimeToHide
        ? TIME_TYPES.filter((t) => !timeToHide.includes(t))
        : TIME_TYPES,
    [existsTimeToHide, timeToHide]
  );


  return (
    <Box pb={24}>
      <Countdown
        date={date}
        renderer={({ completed, ...countProps }) => (
          <Stack
            direction="row"
            justifyContent={align ? align : "start"}
            alignItems="center"
            spacing={16}
          >
            {timeToShows.map((timeType, i) => (
              <CustomCountdownBlock
                key={`${i}_${timeType}`}
                completed={completed}
                time={countProps[timeType]}
                description={t(`paragraphs.countdown.${timeType}`)}
              />
            ))}
          </Stack>
        )}
      />
    </Box>
  );
};

export default CustomCountdown;
