import { Paper, Typography } from "@mui/material";
import { FC } from "react";
import { ICustomCountdownBlockProps } from "./types";
import { useStyles } from "./styles";

const CustomCountdownBlock: FC<ICustomCountdownBlockProps> = ({completed, time, description}) => {
  const styles = useStyles(completed);


    return (<Paper elevation={2} sx={styles.timeBlock}>
        <Typography sx={styles.timeBlockNum} variant="h2" component="span">
            {time}
        </Typography>

        <Typography variant="description">
            {description}
        </Typography>
    </Paper>)
};

export default CustomCountdownBlock