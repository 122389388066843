import { ArrowForward } from "@mui/icons-material";
import { theme } from "@styles/mui/theme";

import {
  ClickAwayListener,
  Fade,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import Link from "next/link";

const TooltipContent = ({
  title,
  href,
  closeTooltip,
}: {
  title: string;
  href?: string;
  closeTooltip: () => void;
}) => (
  <ClickAwayListener onClickAway={closeTooltip}>
    <Typography
      variant="bodyS"
      sx={{
        display: "inline-flex",
        alignItems: "center",
        fontWeight: "800 !important",
        color: theme.palette.common.black,
      }}
    >
      <span>{title}</span>

      {href ? (
        <ArrowForward
          sx={{
            color: theme.palette.primary.main,
            ml: theme.spacing(2),
          }}
          fontSize="small"
        />
      ) : (
        <></>
      )}
    </Typography>
  </ClickAwayListener>
);

const SystemTooltip = styled(
  ({
    className,
    title,
    href,
    open,
    closeTooltip,
    ...props
  }: TooltipProps & {
    title: string;
    href?: string;
    closeTooltip: () => void;
  }) => (
    <Tooltip
      {...props}
      open={open}
      className="system-tooltip"
      classes={{ popper: className }}
      arrow
      placement="top"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      sx={{
        padding: "4px 8px"
      }}
      title={
        href ? (
          <Link href={href}>
            <TooltipContent closeTooltip={closeTooltip} title={title} href={href} />
          </Link>
        ) : (
          <TooltipContent closeTooltip={closeTooltip} title={title} />
        )
      }
      componentsProps={{
        arrow: {
          sx: {
            color: "#fff",
          },
        },
      }}
    />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #fff",
    borderRadius: 0,
  },
}));

export default SystemTooltip;
